import '../../App.css';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { FaTimes, FaPlus } from 'react-icons/fa';
import { MdAccessTime } from 'react-icons/md'
import { TbTrash, TbPencil } from "react-icons/tb";
import { Tab, Tabs, Form, Row, Col, Card, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ModalEditorTexto from './ModalEditorTexto';
import moment from 'moment';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function ModalCriarDemanda({ closeModal, priorizacao }) {
    const { Tipos, Linhas, Fases, Categorias, Capacidades, ListaTipoDemanda, ListaLinhaAtendimento, ListaCategorizacao, ListaCapacidades,
        ListaSetorGerencia, ListaFases, Setores, CriarDemanda, setForm, FormDemanda, setGut, Gut, ObterBSC, DiretrizEstrategica, setgutBSC, GutBSC,
        setlinhaAtendimento, FormLink, setFormLink, FormComentario, setFormComentario, openModal, loading, FormRisco, setFormRisco, setError,
        RTFModal, setRTFModal,
    } = useContext(ApiContext)
    const { btnVer, btnCriar, btnPesquisar, corTerciaria } = useContext(StyleContext)

    const [GutGravidade, setGutGravidade] = useState(0)
    const [GutUrgencia, setGutUrgencia] = useState(0)
    const [GutTendencia, setGutTendencia] = useState(0)
    const [LinkAdd, setLinkAdd] = useState('')
    const [DescricaoAdd, setDescricaoAdd] = useState('')
    const [ComentarioAdd, setComentarioAdd] = useState('')
    const [RiscoAdd, setRiscoAdd] = useState('')
    const [RiscoJustificativaAdd, setRiscoJustificativaAdd] = useState('')
    const [MitigacaoAdd, setMitigacaoAdd] = useState('')
    const [MitigacaoJustificativaAdd, setMitigacaoJustificativaAdd] = useState('')
    const [BSC, setBSC] = useState(0)
    const [attLista, setattLista] = useState(false)
    const [KeyFragment, setKeyFragment] = useState(0)
    const [obrigatorio, setObrigatorio] = useState('')
    const [obrigatorioGut, setObrigatorioGut] = useState('')
    const [editorTexto, setEditorTexto] = useState('')
    const [valorTexto, setValorTexto] = useState('')
    const [valorCusto, setValorCusto] = useState('')
    const [rhCusto, setrhCusto] = useState('')
    const [rhHospedagem, setrhHospedagem] = useState('')
    const [rhLocomocao, setrhLocomocao] = useState('')
    const [rhAlimentacao, setrhAlimentacao] = useState('')

    const handleChange = (event) => {
        setForm({ ...FormDemanda, [event.target.name]: event.target.value })
        const texto = parseInt(event.target.value);
        if (event.target.name === "esforcoTotal" && texto > 100000) {
            setError('Esforço total não pode ser maior que 100000')
            setTimeout(() => {
                setError("");
            }, 6000);
            event.target.value = ""
            return;
        } else if (event.target.name === "esforcoTotal" && texto < 0) {
            setError('Esforço total não pode ser negativo.')
            setTimeout(() => {
                setError("");
            }, 6000);
            event.target.value = ""
            return;
        }

        if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 0) {
            setObrigatorio('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 1) {
            setObrigatorio('')
        } else if (event.target.name == 'faseDemandaId') {
            setObrigatorio('*')
        }

        if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 0) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 1) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 2) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId') {
            setObrigatorioGut('*')
        }
        if (event.target.name == "valor" || event.target.name == "recursosHumanosExternosCusto" || event.target.name == "recursosHumanosExternosHospedagem" || event.target.name == "recursosHumanosExternosAlimentacao" || event.target.name == "recursosHumanosExternosLocomocao") {
            const valor = event.target.value.toString()
            if (valor.length > 15) {
                switch (event.target.name) {
                    case "valor":
                        setValorCusto(valor.slice(0, 15))
                        break
                    case "recursosHumanosExternosCusto":
                        setrhCusto(valor.slice(0, 15))
                        break
                    case "recursosHumanosExternosHospedagem":
                        setrhHospedagem(valor.slice(0, 15))
                        break
                    case "recursosHumanosExternosAlimentacao":
                        setrhAlimentacao(valor.slice(0, 15))
                        break
                    case "recursosHumanosExternosLocomocao":
                        setrhLocomocao(valor.slice(0, 15))
                        break
                }

                setForm({ ...FormDemanda, [event.target.name]: parseFloat(valor.slice(0, 15)) })
                return
            } else {
                switch (event.target.name) {
                    case "valor":
                        setValorCusto(valor)
                        break
                    case "recursosHumanosExternosCusto":
                        setrhCusto(valor)
                        break
                    case "recursosHumanosExternosHospedagem":
                        setrhHospedagem(valor)
                        break
                    case "recursosHumanosExternosAlimentacao":
                        setrhAlimentacao(valor)
                        break
                    case "recursosHumanosExternosLocomocao":
                        setrhLocomocao(valor)
                        break
                }
                setForm({ ...FormDemanda, [event.target.name]: parseFloat(valor) })
                return
            }
        }
        if (event.target.name == "guT_Gravidade") {
            setGutGravidade(event.target.value)
        }
        if (event.target.name == "guT_Urgencia") {
            setGutUrgencia(event.target.value)
        }
        if (event.target.name == "guT_Tendencia") {
            setGutTendencia(event.target.value)
        }
        if (event.target.name == "diretrizEstrategicaId") {
            const valor = JSON.parse(event.target.value)
            setBSC(valor.peso)
        }
        if (event.target.name == "linhaAtendimento") {
            setlinhaAtendimento(event.target.value)
        }
    }

    const handleChangeEditor = (event) => {
        if (event.target.innerHTML === "<p><br></p>") {
            setForm({ ...FormDemanda, [event.target.id]: '' })
        }
        else {
            setForm({ ...FormDemanda, [event.target.id]: event.target.innerHTML })
        }
    }

    const AdicionarLink = () => {
        if (typeof LinkAdd === 'undefined' || LinkAdd === '') {
            setError("Campo link em branco.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        let data = {
            nome: Cookies.get("Username"),
            link: LinkAdd,
            descricao: DescricaoAdd,
        }
        setFormLink(FormLink => [...FormLink, data])
        setLinkAdd("")
        setDescricaoAdd("")
        setattLista(!attLista)
    }

    const AdicionarComentario = () => {
        if (typeof ComentarioAdd === 'undefined' || ComentarioAdd === '') {
            setError("Campo comentário em branco.")
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        const linkRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([^\s]+\.[a-zA-Z]{2,})/g;
        const isLink = linkRegex.test(ComentarioAdd);

        let comentarioRenderizado = ComentarioAdd;
        if (isLink) {
            comentarioRenderizado = ComentarioAdd.replace(linkRegex, (match) => {
                if (match.startsWith("http") || match.startsWith("www")) {
                    if (match.startsWith("www")) {
                        return `<a href="http://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
                    }
                    return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
                } else {
                    return `<a href="http://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
                }
            });
        }

        let data = {
            nome: Cookies.get("Username"),
            comentario: comentarioRenderizado,
        };

        if (typeof comentarioRenderizado === 'undefined') {
            setError("Vazio")
        }

        setFormComentario((FormComentario) => [...FormComentario, data]);
        setComentarioAdd("");
        setattLista(!attLista);
    }

    const AdicionarRisco = () => {
        if (RiscoAdd == '' || RiscoJustificativaAdd == '' || MitigacaoAdd == '' || MitigacaoJustificativaAdd == '') {
            setError("Preencha todos os campos para adicionar um risco.")
            setTimeout(() => {
                setError("")
            }, 6000)
            return
        }
        let data = {
            riscoAtual: RiscoAdd,
            riscoAtualJustificativa: RiscoJustificativaAdd,
            mitigacaoRisco: MitigacaoAdd,
            mitigacaoRiscoJustificativa: MitigacaoJustificativaAdd,
        }
        setFormRisco(FormRisco => [...FormRisco, data])
        setKeyFragment(KeyFragment + 1)
        setRiscoAdd("")
        setRiscoJustificativaAdd("")
        setMitigacaoAdd("")
        setMitigacaoJustificativaAdd("")
    }

    const ExcluirComentario = (props) => {
        FormComentario.splice(props, 1)
        setComentarioAdd("")
        setattLista(!attLista)
    }

    const ExcluirLink = (props) => {
        FormLink.splice(props, 1)
        setattLista(!attLista)
    }

    const ExcluirRisco = (props) => {
        FormRisco.splice(props, 1)
        setattLista(!attLista)
    }

    const fechar = () => {
        closeModal(false)
        setForm({})
    }

    const abrirEditor = (tipo, valor) => {
        setRTFModal(true)
        setEditorTexto(tipo)
        setValorTexto(valor)
    }

    useEffect(() => {
        ListaTipoDemanda('S')
        ListaFases()
        ListaLinhaAtendimento('S')
        ListaCategorizacao('S')
        ListaCapacidades('S')
        ListaSetorGerencia('S')
        ObterBSC()
    }, [])

    useEffect(() => {
        setGut(GutGravidade * GutUrgencia * GutTendencia)
        if (Gut != 0 && BSC != 0) {
            setgutBSC(Gut * BSC || 0)
        }
        else {
            setgutBSC(0)
        }
    }, [GutGravidade, GutUrgencia, GutTendencia, BSC, Gut])

    useEffect(() => {
        setFormComentario([])
        setFormLink([])
        setFormRisco([])
    }, [openModal])


    return (
        <>
            {loading &&
                <div className="loading-screen">
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                </div>
            }
            {RTFModal &&
                <ModalEditorTexto tipoEdicao={editorTexto} valor={valorTexto} />
            }
            <div className="modal-criar-demanda">
                <div className="modal-criar-demanda-interno">
                    <div className="header-modal-criar-demanda">
                        <div>
                            <h1 className="h1-modal">Criar nova demanda</h1>
                        </div>
                        <div className="btn-close-modal" onClick={() => fechar()}>
                            <FaTimes />
                        </div>
                    </div>
                    <form>
                        <div className="modal-content-criar-demanda">
                            <Tabs
                                defaultActiveKey="info"
                                id="fill-tab-example"
                                className="mb-3"
                                fill

                            >
                                <Tab eventKey="info" title={<span>Informações</span>}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>Título *</Form.Label>
                                                <Form.Control onBlur={handleChange} name="titulo" />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Descrição *
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('descricao', FormDemanda?.descricao)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="descricao"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.descricao }}>
                                                </div>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Cenário atual *
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('cenarioAtual', FormDemanda?.cenarioAtual)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="cenarioAtual"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.cenarioAtual }}>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Fase *</Form.Label>
                                                <Form.Select aria-label="Default select example" onBlur={handleChange} name="faseDemandaId">
                                                    <option value="0">Selecione</option>
                                                    {Fases?.map((f, index) => {
                                                        return (
                                                            <option key={index} value={JSON.stringify({ id: f.id, descricao: f.descricao })}>{f.descricao}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Problema identificado no cenário atual *
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('problemaIdentificadoCenarioAtual', FormDemanda?.problemaIdentificadoCenarioAtual)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="problemaIdentificadoCenarioAtual"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.problemaIdentificadoCenarioAtual }}>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Gerência Solicitante *</Form.Label>
                                                <Form.Select aria-label="Default select example" onBlur={handleChange} name="setorGerenciaDemandaId">
                                                    <option value="0">Selecione</option>
                                                    {Setores?.map((s) => {
                                                        return (
                                                            <option key={s.id} value={s.id}>{s.descricao}</option>
                                                        )

                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Linha de atendimento *</Form.Label>
                                                <Form.Select aria-label="Default select example" onBlur={handleChange} name="linhaAtendimentoId">
                                                    <option value="0">Selecione</option>
                                                    {
                                                        Linhas?.map((item) => {
                                                            return (
                                                                <option key={item.id} value={item.id}>{item.descricao}</option>
                                                            )
                                                        })

                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Solicitante *</Form.Label>
                                                <Form.Control onBlur={handleChange} name="solicitante" maxLength={100} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="objs" title={<span>Objetivos</span>}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Objetivo {obrigatorio}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('objetivo', FormDemanda?.objetivo)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="objetivo"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.objetivo }}>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Resultado esperado {obrigatorio}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('resultadoEsperado', FormDemanda?.resultadoEsperado)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="resultadoEsperado"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.resultadoEsperado }}>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Necessidade de negócio {obrigatorio}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('necessidadeNegocio', FormDemanda?.necessidadeNegocio)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="necessidadeNegocio"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.necessidadeNegocio }}>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Oportunidade {obrigatorio}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('oportunidade', FormDemanda?.oportunidade)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="oportunidade"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.oportunidade }}>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Label>Prazo para retorno esperado {obrigatorio}</Form.Label>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                value="Imediato"
                                                label="Imediato"
                                                type={type}
                                                id={`inline-${type}-1`}
                                                onBlur={handleChange}
                                                name="prazoRetornoEsperado"
                                            />
                                            <Form.Check
                                                inline
                                                value="Médio"
                                                label="Médio"
                                                type={type}
                                                id={`inline-${type}-2`}
                                                onBlur={handleChange}
                                                name="prazoRetornoEsperado"
                                            />
                                            <Form.Check
                                                inline
                                                value="Longo"
                                                label="Longo"
                                                type={type}
                                                id={`inline-${type}-3`}
                                                onBlur={handleChange}
                                                name="prazoRetornoEsperado"
                                            />
                                        </div>
                                    ))}
                                </Tab>
                                <Tab eventKey="urgent" title={<span>Risco</span>}>
                                    <Row>
                                        <Fragment key={KeyFragment}>
                                            <Col>
                                                <Form.Label>Risco Atual</Form.Label>
                                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    {['radio'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Baixo"
                                                                value="Baixo"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                name="riscoAtual"
                                                                onChange={(e) => setRiscoAdd(e.target.value)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Médio"
                                                                value="Médio"
                                                                type={type}
                                                                id={`inline-${type}-2`}
                                                                name="riscoAtual"
                                                                onChange={(e) => setRiscoAdd(e.target.value)}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Alto"
                                                                value="Alto"
                                                                type={type}
                                                                id={`inline-${type}-3`}
                                                                name="riscoAtual"
                                                                onChange={(e) => setRiscoAdd(e.target.value)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Justificativa do risco</Form.Label>
                                                    <Form.Control as="textarea" rows={2} onChange={(e) => setRiscoJustificativaAdd(e.target.value)} name="riscoAtualJustificativa" />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Label>Mitigação de risco</Form.Label>
                                                {['radio'].map((type) => (
                                                    <div key={`inline-${type}`} className="mb-3">
                                                        <Form.Check
                                                            inline
                                                            label="Não mitigável"
                                                            value="Não mitigável"
                                                            type={type}
                                                            id={`inline-${type}-1`}
                                                            name="mitigacaoRisco"
                                                            onChange={(e) => setMitigacaoAdd(e.target.value)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Parcialmente"
                                                            value="Parcialmente"
                                                            type={type}
                                                            id={`inline-${type}-2`}
                                                            name="mitigacaoRisco"
                                                            onChange={(e) => setMitigacaoAdd(e.target.value)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Totalmente"
                                                            value="Totalmente"
                                                            type={type}
                                                            id={`inline-${type}-3`}
                                                            name="mitigacaoRisco"
                                                            onChange={(e) => setMitigacaoAdd(e.target.value)}
                                                        />
                                                    </div>
                                                ))}
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Justificativa da mitigação de risco</Form.Label>
                                                    <Form.Control as="textarea" rows={2} onChange={(e) => setMitigacaoJustificativaAdd(e.target.value)} name="mitigacaoRiscoJustificativa" />
                                                </Form.Group>
                                            </Col>
                                        </Fragment>
                                    </Row>
                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                        <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarRisco()}><FaPlus style={{ marginRight: '5px' }} />Adicionar risco</button>
                                    </div>
                                    <p>Riscos Adicionados</p>
                                    <div>
                                        <div className="secao-risco">
                                            {
                                                FormRisco?.map((item, index) => {
                                                    return (
                                                        <div key={index} className='content-risco'>
                                                            <div>
                                                                <p>Risco Atual: <span>{item.riscoAtual}</span></p>
                                                                <p>Mitigação de risco: <span>{item.mitigacaoRisco}</span></p>
                                                            </div>
                                                            <div>
                                                                <p>Justificativa do risco: <span>{item.riscoAtualJustificativa}</span></p>
                                                                <p>Justificativa da mitigação de risco: <span>{item.mitigacaoRiscoJustificativa}</span></p>
                                                            </div>
                                                            <div>
                                                                <button type="button" className="btn-del" onClick={() => ExcluirRisco(index)}><TbTrash /></button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="params" title={<span>Parâmetros</span>}>
                                    <Row>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>Expectativa de entrega</Form.Label>
                                                <Form.Control defaultValue={new Date("01/01/0001")} type="date" onBlur={handleChange} name="expectativaEntrega" />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Data limite</Form.Label>
                                                <Form.Control type="date" defaultValue={new Date("01/01/0001")} onBlur={handleChange} name="dataLimite" />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tipo da Demanda {obrigatorio}</Form.Label>
                                                <Form.Select aria-label="Default select example" onBlur={handleChange} name="tipoDemandaId">
                                                    <option value="0">Selecione</option>
                                                    {Tipos?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id} >
                                                                {item.descricao}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Justificativa para data limite
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' onClick={() => abrirEditor('justificativaDataLimite', FormDemanda?.justificativaDataLimite)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="justificativaDataLimite"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.justificativaDataLimite }}>
                                                </div>
                                            </Form.Group>
                                            <Form.Label>Classificação da demanda {obrigatorio}</Form.Label>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Projeto"
                                                        value="Projeto"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        onBlur={handleChange}
                                                        name="classificacaoDemanda"
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Melhoria"
                                                        value="Melhoria"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        onBlur={handleChange}
                                                        name="classificacaoDemanda"
                                                    />
                                                </div>
                                            ))}
                                            <Form.Label>Deliberação pelo comitê</Form.Label>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Sim"
                                                        value={true}
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        onBlur={handleChange}
                                                        name="deliberacaoComite"
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Não"
                                                        value={false}
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        onBlur={handleChange}
                                                        name="deliberacaoComite"
                                                        defaultChecked
                                                    />
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="gut/bsc" title={<span>GUT/BSC</span>}>
                                    <Row>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Esforço total (horas) {obrigatorio}</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control type="number" min="0" onBlur={handleChange} name="esforcoTotal" maxLength={50} />
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Diretriz estratégica {obrigatorioGut}</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="diretrizEstrategicaId">
                                                            <option value="0">Selecione</option>
                                                            {DiretrizEstrategica?.map((d, index) => {
                                                                return (
                                                                    <option key={index} value={JSON.stringify({ id: d.id, peso: d.pesoBSC })}>{d.descricao}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Justificativa do esforço
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' onClick={() => abrirEditor('justificativaEsforcoTotal', FormDemanda?.justificativaEsforcoTotal)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    id="justificativaEsforcoTotal"
                                                    contentEditable="true"
                                                    className="input-descricao"
                                                    onBlur={handleChangeEditor}
                                                    dangerouslySetInnerHTML={{ __html: FormDemanda?.justificativaEsforcoTotal }}>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Capacidade de negócio atendida {obrigatorio}</Form.Label>
                                                <Form.Select aria-label="Default select example" onBlur={handleChange} name="capacidadeNegocioDemandaId">
                                                    <option value="0">Selecione</option>
                                                    {Capacidades?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.descricao}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Categorização estratégica {obrigatorio}</Form.Label>
                                                <Form.Select aria-label="Default select example" onBlur={handleChange} name="categorizacaoEstrategicaDemandaId">
                                                    <option value="0">Selecione</option>
                                                    {Categorias?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.descricao}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Card style={{ width: '35rem' }}>
                                                <Row style={{ padding: "15px 15px" }}>
                                                    <Col style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                        <div className="container-gut">
                                                            <div>
                                                                <p className='pontuacao-gut'>Pontuação</p>
                                                                <p className='title-gut'>GUT</p>
                                                            </div>
                                                            <div>
                                                                <p className='number-gut'>{Gut.toString()}</p>
                                                            </div>
                                                        </div>
                                                        <div className="container-gut">
                                                            <div>
                                                                <p className='pontuacao-gut'>Pontuação</p>
                                                                <p className='title-gut'>GUT/BSC</p>
                                                            </div>
                                                            <div>
                                                                <p className='number-gut'>{GutBSC.toFixed(2).toString()}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Gravidade {obrigatorioGut}</Form.Label>
                                                            <Form.Select aria-label="Default select example" onChange={handleChange} name="guT_Gravidade" className="mb-3">
                                                                <option value={0}>Selecione</option>
                                                                <option value="1">Sem gravidade</option>
                                                                <option value="2">Pouco grave</option>
                                                                <option value="3">Grave</option>
                                                                <option value="4">Muito grave</option>
                                                                <option value="5">Extremamente grave</option>
                                                            </Form.Select>
                                                            <Form.Label>Urgência {obrigatorioGut}</Form.Label>
                                                            <Form.Select aria-label="Default select example" onChange={handleChange} name="guT_Urgencia" className="mb-3">
                                                                <option value={0}>Selecione</option>
                                                                <option value="1">Longuíssimo prazo</option>
                                                                <option value="2">Longo prazo</option>
                                                                <option value="3">Médio prazo</option>
                                                                <option value="4">Curto</option>
                                                                <option value="5">Imediatamente</option>
                                                            </Form.Select>
                                                            <Form.Label>Tendência {obrigatorioGut}</Form.Label>
                                                            <Form.Select aria-label="Default select example" onChange={handleChange} name="guT_Tendencia">
                                                                <option value={0}>Selecione</option>
                                                                <option value="1">Desaparece</option>
                                                                <option value="2">Reduz-se ligeiramente</option>
                                                                <option value="3">Permanece</option>
                                                                <option value="4">Aumenta</option>
                                                                <option value="5">Piora muito</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="comentarioslinks" title={<span>Comentário/Link</span>}>
                                    <div style={{ paddingTop: "15px" }}>
                                        Adicione comentários ou links na sua demanda:
                                        <Form.Group className="mb-3">
                                            <Form.Label>Comentário</Form.Label>
                                            <Form.Control value={ComentarioAdd} as="textarea" rows={2} onChange={(e) => setComentarioAdd(e.target.value)} name="comentario" />
                                        </Form.Group>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarComentario()}><FaPlus style={{ marginRight: "5px" }} />Adicionar comentário</button>
                                        </div>
                                        Comentários adicionados
                                        <div className="secao-comentario">
                                            {
                                                FormComentario?.map((item, index) => {
                                                    return (
                                                        <div key={index} className="content-comentario">
                                                            <div>
                                                                {/* <p className="p-title">{item.nome}</p> */}
                                                                <p className="p-content">{moment(item.dataHora).format('L')} às {moment(item.dataHora).format('LT')}</p>
                                                            </div>
                                                            <div className="div-comentario">
                                                                <p className="p-title">Comentário: </p>
                                                                <span><div dangerouslySetInnerHTML={{ __html: item.comentario }} /></span>
                                                            </div>
                                                            <div className="div-button">
                                                                <button type="button" className="btn-del" onClick={() => ExcluirComentario(index)}><TbTrash /></button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Link</Form.Label>
                                            <Form.Control value={LinkAdd} onChange={(e) => setLinkAdd(e.target.value)} name="links" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Descrição</Form.Label>
                                            <Form.Control value={DescricaoAdd} as="textarea" rows={2} onChange={(e) => setDescricaoAdd(e.target.value)} name="descricaoLink" />
                                        </Form.Group>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <button className="btn-dash btn-pesquisar" style={btnPesquisar} type="button" onClick={() => AdicionarLink()}><FaPlus style={{ marginRight: "5px" }} />Adicionar link</button>
                                        </div>
                                    </div>
                                    Links adicionados
                                    <div className="secao-link">
                                        {FormLink?.map((item, index) => {
                                            return (
                                                <div key={item.chaveLink} className="content-comentario">
                                                    <div>
                                                        {/* <p className="p-title">{item.nome}</p> */}
                                                        <p className="p-content">{moment(item.dataHora).format('L')} às {moment(item.dataHora).format('LT')}</p>
                                                    </div>
                                                    <div className="div-link">
                                                        <p className="p-title">Link: </p>
                                                        <span><p className="p-content"><a href={item.link} target="_blank">{item.link}</a></p></span>
                                                    </div>
                                                    <div className="div-comentario">
                                                        <p className="p-title">Comentário: </p>
                                                        <span><p>{item.descricao}</p></span>
                                                    </div>
                                                    <div className="div-button">
                                                        <button type="button" className="btn-del" onClick={() => ExcluirLink(index)}><TbTrash /></button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Tab>
                                <Tab eventKey="custos" title={<span>Custos</span>}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>Valor Demanda</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} value={valorCusto} name="valor" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>RH Interno Quantidade</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} name="recursosHumanosInternosQuantidade" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>RH Interno Competência</Form.Label>
                                                <Form.Control onBlur={handleChange} maxLength="100" name="recursosHumanosInternosCompetencias" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>RH Externo Custo</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} value={rhCusto} name="recursosHumanosExternosCusto" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>RH Externo Hospedagem</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} value={rhHospedagem} name="recursosHumanosExternosHospedagem" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>RH Externo Alimentação</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} value={rhAlimentacao} name="recursosHumanosExternosAlimentacao" />
                                            </Form.Group>

                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>RH Externo Locomoção</Form.Label>
                                                <Form.Control type="number" value={rhLocomocao} onChange={handleChange} name="recursosHumanosExternosLocomocao" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>RH Externo Tempo</Form.Label>
                                                <Form.Control maxLength="100" onBlur={handleChange} name="recursosHumanosExternosTempo" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>RH Externo Tipo Atendimento</Form.Label>
                                                <Form.Control maxLength="100" onBlur={handleChange} name="recursosHumanosExternosTipoAtendimento" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>Infraestrutura</Form.Label>
                                                <Form.Control maxLength="100" onBlur={handleChange} name="infraestruturaNecessariaExecucao" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>Ano Base</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} name="anoBaseOrcamento" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </div>
                        <div className="footer-modal-criar-demanda">
                            <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fechar()}>Cancelar</button>
                            <button type="button" className="btn-dash btn-criar" style={btnCriar} onClick={() => CriarDemanda(priorizacao)}>Salvar</button>
                        </div>
                    </form>
                </div >
            </div >
        </>
    )
}

export default ModalCriarDemanda