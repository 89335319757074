import '../../App.css';
import React, { useContext, useEffect } from 'react';
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { FormControl } from '@mui/material'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { MdRefresh } from 'react-icons/md'
import { ApiContext } from '../../context/ApiContext';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, PieSeries, AccumulationTooltip } from '@syncfusion/ej2-react-charts';
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { StyleContext } from '../../context/StyleContext';

function BSC() {

    const { ObterBSC, DiretrizEstrategica, Prioridades, ResumoPrioridades, SimularBSC, BSCDados, loading, SalvarBSC, Success, Error, setError, show, showOrHide, setShow } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    let arrayD = []
    DiretrizEstrategica?.map((a) => {
        return (
            a.itens.map((b) => {
                arrayD.push(b)
            })
        )
    })

    const cor = (descricao) => {
        switch (descricao) {
            case "Normal":
                return "input-green"
            case "Importante":
                return "input-yellow"
            case "Prioritária":
                return "input-red"
            default:
                break;
        }
    }

    const corInput = (descricao) => {
        switch (descricao) {
            case 'Normal':
                return "input-green"
            case 'Importante':
                return "input-yellow"
            case 'Prioritária':
                return "input-red"
            default:
                break;
        }
    }

    const mudarPorcentagemBsc = (v, i) => {
        const texto = parseInt(v.target.value);

        if (texto > 100) {
            setError('Valor não pode ser maior que 100%')
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        } else if (texto < 0) {
            setError('Valor não pode ser negativo.')
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        setShow(true)
        try {
            BSCDados.diretrizEstrategica.forEach((item, index) => {
                if (index == i) {
                    item.pesoPorcentagem = parseInt(v.target.value)
                }
            })
        }
        catch (ex) {
        }
        finally {
            SimularBSC()
        }
    };

    const mudarPrioridadeBsc = (v, key) => {
        try {
            BSCDados.diretrizEstrategicaItems.forEach((item) => {
                if (key == item.key) {
                    item.diretrizEstrategicaPrioridadeId = parseInt(v.target.value)
                }
            })
        }
        catch (ex) {
        }
        finally {
            SimularBSC()
        }

    };

    const onPointRender = (args) => {
        if (args.point.x.indexOf('Normal') > -1) {
            args.fill = '#16A34A';
        } else if (args.point.x.indexOf('Importante') > -1) {
            args.fill = '#F87171';
        } else if (args.point.x.indexOf('Prioritária') > -1) {
            args.fill = '#FACC15';
        }
        else {
            args.fill = '#7f84e8';
        }
    };

    useEffect(() => {
        ObterBSC()
    }, [])

    return (
        <>
            <Header pagina={'BSC'}></Header>
            <MenuLateral></MenuLateral>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <div className="container-pages">
                {loading &&
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
                <div className='row'>
                    <div className='col-lg-4 container'>
                        <div>
                            <div className="col-md-12 section-bsc-grafico">
                                <div className="content-prioridade">
                                    <h2 className="h2-bsc">Impacto Objetivos de Negócio</h2>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', marginTop: '25px', padding: '0 20px' }}>
                                        <AccumulationChartComponent id='bsc'
                                            pointRender={onPointRender}
                                            tooltip={{ enable: true }}
                                            width='200'
                                            height='200'

                                        >
                                            <Inject services={[PieSeries, AccumulationTooltip]} />
                                            <AccumulationSeriesCollectionDirective>
                                                <AccumulationSeriesDirective
                                                    dataSource={ResumoPrioridades}
                                                    yName='y'
                                                    xName='x'
                                                    type='Pie'
                                                    pointColorMapping='fill'
                                                    border={{ color: '#fff', width: "1px" }}
                                                >
                                                </AccumulationSeriesDirective>
                                            </AccumulationSeriesCollectionDirective>
                                        </AccumulationChartComponent>
                                        <div className='status'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='status-concluido' /> Normal
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className='status-prioritario'></span> Prioritária
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className='status-atraso'></span> Importante
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 section-bsc">
                                <div className="content-prioridade">
                                    <div className="header-prioridade">
                                        <h2 className="h2-bsc">Ordem de Prioridade</h2>
                                    </div>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            {
                                                arrayD?.sort((a, b) => a.ordem - b.ordem).map((o, index) => {
                                                    return (
                                                        <tr key={index} className="tr-bsc">
                                                            <td className='td-bsc-ordem'>
                                                                <div className='ordem'>
                                                                    <div>{o.ordem}º</div>
                                                                    <div>{o.letra}</div>
                                                                </div>
                                                                <div>{o.descricao}</div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-8 container'>
                        <div className='row'>
                            {DiretrizEstrategica?.map((item, index) => {
                                return (
                                    <div className='col-md-6' key={index}>
                                        <div className="section-bsc">
                                            <div className="header-bsc">
                                                <h2 className="h2-bsc">{item.descricao}</h2>
                                                <div style={{ display: 'flex', alignItems: 'center' }} className='content-value-bsc'>
                                                    <input type='number' min="0"
                                                        max="100" className="value-bsc" defaultValue={item.pesoPorcentagem} onFocus={() => showOrHide()}
                                                        onBlur={(v) => mudarPorcentagemBsc(v, index)} /><span className='porcentagem' style={{ fontSize: '16px' }}>%</span>
                                                </div>
                                            </div>

                                            <div className="content-bsc">
                                                <table style={{ width: "100%" }} >
                                                    <thead>
                                                        <tr style={{ fontSize: '14px', height: '21px' }}>
                                                            <th className='th-bsc'>Descrição</th>
                                                            <th className='th-bsc'>Impacto</th>
                                                            <th className='th-bsc'>Ordem</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {item.itens?.map((a, index) => {
                                                            return (
                                                                <tr className="tr-bsc" key={index}>
                                                                    <td className='td-bsc'>{a.letra} - {a.descricao}<OverlayTrigger placement="right" overlay={<Popover id="popover-basic">
                                                                        <Popover.Header as="h3">Informações</Popover.Header>
                                                                        <Popover.Body>
                                                                            {a.descricaoCompleta.replace(/(?:\\[rn])+/g, " ")}
                                                                        </Popover.Body>
                                                                    </Popover>} >
                                                                        <span className='tooltip-bsc'>?</span>
                                                                    </OverlayTrigger ></td>
                                                                    <td className='td-bsc'>
                                                                        <FormControl
                                                                            className={corInput(a?.diretrizEstrategicaPrioridade?.descricao)}
                                                                            name='selectPrioridade'
                                                                            as="select"
                                                                            style={{ padding: "2px 5px" }}
                                                                            onChange={(v) => mudarPrioridadeBsc(v, a.key)}
                                                                        >
                                                                            {Prioridades?.map((p, index) => {
                                                                                return (
                                                                                    <option
                                                                                        className={cor(p.descricao)}
                                                                                        key={index}
                                                                                        value={p.id}
                                                                                        selected={p.id === a.diretrizEstrategicaPrioridadeId ? true : false}
                                                                                    >
                                                                                        {p.descricao}
                                                                                    </option>
                                                                                )
                                                                            })}
                                                                        </FormControl>
                                                                    </td>
                                                                    <td className='td-bsc' style={{ textAlign: 'center' }}>{a.ordem}º</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </div>
                {show &&
                    <div className='container-btn-bsc'>
                        <button className='btn-dash btn-ver' style={btnVer}><MdRefresh style={{ marginRight: "5px" }} />Atualizar</button>
                        <button className='btn-dash btn-criar' style={btnCriar} onClick={() => SalvarBSC()}>Salvar</button>
                    </div>
                }
            </div>
            <Footer></Footer>
        </>
    );
    function onTextRender(args) {
        args.text = args.point.x + ': ' + args.point.y + '%';
    }
}

export default BSC;