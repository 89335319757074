import React, { createContext, useLayoutEffect, useState, useCallback } from 'react'
import axios from 'axios'
import baseurl from '../services/empresa/baseurl'
import Cookies from 'js-cookie'


export const AdminContext = createContext()

export const AdminContextProvider = ({ children }) => {
    const [AuthAdmin, setAuthAdmin] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [Erro, setErro] = useState("")
    const [Success, setSuccess] = useState("")
    const [Empresas, setEmpresas] = useState([])
    const [EmpresaAtual, setEmpresaAtual] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [openModalEditar, setOpenModalEditar] = useState(false)
    const [openModalLayout, setOpenModalLayout] = useState(false)
    const [openModalEditarLayout, setOpenModalEditarLayout] = useState(false)
    const [openModalExcluirLayout, setOpenModalExcluirLayout] = useState(false)
    const [Cadastro, setCadastro] = useState([])
    const [DadosLayout, setDadosLayout] = useState([])
    const [LayoutsLista, setLayoutsLista] = useState([])
    const [LayoutAtual, setLayoutAtual] = useState({})
    const [keyLayout, setKeyLayout] = useState('')


    const AuthUserAdmin = async (event) => {
        event.preventDefault()
        let data = {
            email: email,
            senha: password,
        }
        setLoading(true)
        return axios({
            url: baseurl + 'Autenticacao/LoginAdmin',
            method: 'POST',
            data: data,
        }).then((response) => {
            Cookies.set('TokenAdmin', response.data.accessToken)
            Cookies.set('Username', response.data.userName)
            Cookies.set('Role', response.data.role)
            setAuthAdmin(true)
            setLoading(false)
            return Promise.resolve()
        }).catch((error) => {
            setLoading(false)
            setErro(error.response.data)
            setTimeout(() => {
                setErro("")
            }, 4000);
            return Promise.reject(error)
        })
    }

    const obterEmpresas = useCallback(async () => {
        let arrayEmpresas = [];
        let objEmpresas = {};
        const token = Cookies.get("TokenAdmin");
        setLoading(true)
        return axios({
            url: baseurl + "Empresa/ListarTodas",
            method: "GET",
            timeout: 15000,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                setLoading(false)
                const emp = eval(response.data);
                emp.forEach((e) => {
                    objEmpresas = {
                        id: e.id,
                        value: e.fantasia,
                        razaoSocial: e.razaoSocial,
                        fantasia: e.fantasia,
                        keyEmpresa: e.key,
                        cnpj: e.cnpj,
                        telefone: e.telefone,
                        email: e.email,
                        endereco: e.endereco,
                        enderecoNumero: e.enderecoNumero,
                        enderecoBairro: e.enderecoBairro,
                        enderecoCidade: e.enderecoCidade,
                        enderecoUF: e.enderecoUF,
                        enderecoCEP: e.enderecoCEP,
                        ativo: e.ativo,
                    };
                    arrayEmpresas.push(objEmpresas);
                })
                setEmpresas(arrayEmpresas)
            })
            .catch((error) => {
                setLoading(false)
                if (error.response?.status === 401) {
                    setAuthAdmin(false)
                    setErro(`Sua sessão expirou, faça login novamente`)
                    setTimeout(() => {
                        setErro("");
                    }, 6000);
                    return
                }
                return Promise.reject(error);
            })

    }, [])

    const CadastrarEmpresa = async (event) => {
        setLoading(true)
        event.preventDefault();
        const token = Cookies.get("TokenAdmin");
        try {
            if (Cadastro === "") {
                setLoading(false)
                setErro("Formulário vazio.");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.razaoSocial === "undefined") {
                setLoading(false)
                setErro("Razão social não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.fantasia === "undefined") {
                setLoading(false)
                setErro("Fantasia não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.cnpj === "undefined") {
                setLoading(false)
                setErro("CNPJ não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.telefone === "undefined") {
                setLoading(false)
                setErro("Telefone não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.email === "undefined") {
                setLoading(false)
                setErro("E-mail não informado");
                setTimeout(() => {
                    setErro("");
                }, 2000);
                return;
            }
            if (typeof Cadastro.emailLogin === "undefined") {
                setLoading(false)
                setErro("E-mail não informado");
                setTimeout(() => {
                    setErro("");
                }, 2000);
                return;
            }
            if (typeof Cadastro.endereco === "undefined") {
                setLoading(false)
                setErro("Endereço não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.enderecoNumero === "undefined") {
                setLoading(false)
                setErro("Número não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.enderecoBairro === "undefined") {
                setLoading(false)
                setErro("Bairro não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.enderecoCidade === "undefined") {
                setLoading(false)
                setErro("Cidade não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.enderecoUF === "undefined") {
                setLoading(false)
                setErro("Estado não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof Cadastro.enderecoCEP === "undefined") {
                setLoading(false)
                setErro("CEP não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
        } catch (error) {
            setLoading(false)
            error.response.data.map((er) => {
                setErro(`${er.campo}: ${er.mensagem}`)
            })
        }
        const data = {
            key: Cadastro.key,
            razaoSocial: Cadastro.razaoSocial,
            fantasia: Cadastro.fantasia,
            cnpj: Cadastro.cnpj,
            telefone: Cadastro.telefone,
            email: Cadastro.email,
            endereco: Cadastro.endereco,
            enderecoNumero: Cadastro.enderecoNumero,
            enderecoBairro: Cadastro.enderecoBairro,
            enderecoCidade: Cadastro.enderecoCidade,
            enderecoUF: Cadastro.enderecoUF,
            enderecoCEP: Cadastro.enderecoCEP,
            nome: Cadastro.nome,
            emailLogin: Cadastro.emailLogin
        };
        return axios({
            url: baseurl + "Empresa/Criar",
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: data,
        })
            .then(() => {
                setSuccess("Empresa cadastrada com sucesso!");
                setOpenModal(false);
                setTimeout(() => {
                    setSuccess("");
                }, 6000);
                setLoading(false);
                obterEmpresas()
                setCadastro([])
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.status === 401) {
                    setAuthAdmin(false)
                    setErro(`Sua sessão expirou, faça login novamente`)
                    setTimeout(() => {
                        setErro("");
                    }, 6000);
                    return
                }
                error.response.data.map((er) => {
                    setErro(`${er.campo}: ${er.mensagem}`)
                })
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return Promise.reject(error);
            });
    }

    const EditarEmpresa = async (event) => {
        setLoading(true)
        event.preventDefault();
        const token = Cookies.get("TokenAdmin");
        try {
            if (EmpresaAtual === "") {
                setLoading(false)
                setErro("Formulário vazio.");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.razaoSocial === "undefined") {
                setLoading(false)
                setErro("Razão social não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.fantasia === "undefined") {
                setLoading(false)
                setErro("Fantasia não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.cnpj === "undefined") {
                setLoading(false)
                setErro("CNPJ não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.telefone === "undefined") {
                setLoading(false)
                setErro("Telefone não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.email === "undefined") {
                setLoading(false)
                setErro("E-mail não informado");
                setTimeout(() => {
                    setErro("");
                }, 2000);
                return;
            }
            if (typeof EmpresaAtual.endereco === "undefined") {
                setLoading(false)
                setErro("Endereço não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.enderecoNumero === "undefined") {
                setLoading(false)
                setErro("Número não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.enderecoBairro === "undefined") {
                setLoading(false)
                setErro("Bairro não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.enderecoCidade === "undefined") {
                setLoading(false)
                setErro("Cidade não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.enderecoUF === "undefined") {
                setLoading(false)
                setErro("Estado não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof EmpresaAtual.enderecoCEP === "undefined") {
                setLoading(false)
                setErro("CEP não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
        } catch (error) {
            setLoading(false)
            error.response.data.map((er) => {
                setErro(`${er.campo}: ${er.mensagem}`)
            })
        }
        const data = {
            key: EmpresaAtual.keyEmpresa,
            razaoSocial: EmpresaAtual.razaoSocial,
            fantasia: EmpresaAtual.fantasia,
            cnpj: EmpresaAtual.cnpj,
            telefone: EmpresaAtual.telefone,
            email: EmpresaAtual.email,
            endereco: EmpresaAtual.endereco,
            enderecoNumero: EmpresaAtual.enderecoNumero,
            enderecoBairro: EmpresaAtual.enderecoBairro,
            enderecoCidade: EmpresaAtual.enderecoCidade,
            enderecoUF: EmpresaAtual.enderecoUF,
            enderecoCEP: EmpresaAtual.enderecoCEP,
        };
        return axios({
            url: baseurl + "Empresa/Alterar",
            method: "PUT",
            headers: { Authorization: `Bearer ${token}` },
            data: data,
        })
            .then((response) => {
                setSuccess("Empresa alterada com sucesso!");
                setOpenModalEditar(false);
                setTimeout(() => {
                    setSuccess("");
                }, 6000);
                setLoading(false);
                obterEmpresas()
                setEmpresaAtual({})
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.status === 401) {
                    setAuthAdmin(false)
                    setErro(`Sua sessão expirou, faça login novamente`)
                    setTimeout(() => {
                        setErro("");
                    }, 6000);
                    return
                }
                error.response.data.map((er) => {
                    setErro(`${er.campo}: ${er.mensagem}`)
                })
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return Promise.reject(error);
            });
    }

    const CadastrarLayout = async (event) => {
        setLoading(true)
        event.preventDefault();
        const token = Cookies.get("TokenAdmin");
        try {
            if (DadosLayout === "") {
                setLoading(false)
                setErro("Formulário vazio.");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof DadosLayout.corPrimaria === "undefined") {
                setLoading(false)
                setErro("Cor primária não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof DadosLayout.corSecundaria === "undefined") {
                setLoading(false)
                setErro("Cor secundária não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof DadosLayout.corTerciaria === "undefined") {
                setLoading(false)
                setErro("Cor terciária não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof DadosLayout.logoLogin === "undefined") {
                setLoading(false)
                setErro("Logo do login não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof DadosLayout.logoNavbar === "undefined") {
                setLoading(false)
                setErro("Logo branca não informado");
                setTimeout(() => {
                    setErro("");
                }, 2000);
                return;
            }
            if (typeof DadosLayout.logoMenu === "undefined") {
                setLoading(false)
                setErro("Logo do menu não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof DadosLayout.logoBackground === "undefined") {
                setLoading(false)
                setErro("Background não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof DadosLayout.url === "undefined") {
                setLoading(false)
                setErro("Url não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
        } catch (error) {
            setLoading(false)
            error.response.data.map((er) => {
                setErro(`${er.campo}: ${er.mensagem}`)
            })
        }
        const data = {
            corPrimaria: DadosLayout.corPrimaria,
            corSecundaria: DadosLayout.corSecundaria,
            corTerciaria: DadosLayout.corTerciaria,
            logoLogin: DadosLayout.logoLogin,
            logoNavbar: DadosLayout.logoNavbar,
            logoMenu: DadosLayout.logoMenu,
            logoBackground: DadosLayout.logoBackground,
            url: DadosLayout.url
        };
        return axios({
            url: baseurl + "ConfiguracaoCor/Adicionar",
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: data,
        })
            .then(() => {
                setSuccess("Layout cadastrado com sucesso!");
                setOpenModalLayout(false);
                setTimeout(() => {
                    setSuccess("");
                }, 6000);
                setLoading(false);
                obterLayouts()
                setDadosLayout([])
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.status === 401) {
                    setAuthAdmin(false)
                    setErro(`Sua sessão expirou, faça login novamente`)
                    setTimeout(() => {
                        setErro("");
                    }, 6000);
                    return
                }
                error.response.data.map((er) => {
                    setErro(`${er.campo}: ${er.mensagem}`)
                })
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return Promise.reject(error);
            });
    }

    const EditarLayout = async (event) => {
        setLoading(true)
        event.preventDefault();
        const token = Cookies.get("TokenAdmin");
        try {
            if (LayoutAtual === "") {
                setLoading(false)
                setErro("Formulário vazio.");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof LayoutAtual.corPrimaria === "undefined") {
                setLoading(false)
                setErro("Cor primária não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof LayoutAtual.corSecundaria === "undefined") {
                setLoading(false)
                setErro("Cor secundária não informada");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof LayoutAtual.corTerciaria === "undefined") {
                setLoading(false)
                setErro("Cor terciária não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof LayoutAtual.logoLogin === "undefined") {
                setLoading(false)
                setErro("Logo do login não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof LayoutAtual.logoNavbar === "undefined") {
                setLoading(false)
                setErro("Logo branca não informado");
                setTimeout(() => {
                    setErro("");
                }, 2000);
                return;
            }
            if (typeof LayoutAtual.logoMenu === "undefined") {
                setLoading(false)
                setErro("Logo do menu não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof LayoutAtual.logoBackground === "undefined") {
                setLoading(false)
                setErro("Background não informado");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
            if (typeof LayoutAtual.url === "undefined") {
                setErro("Url não informado");
                setLoading(false)
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return;
            }
        } catch (error) {
            setLoading(false)
            error.response.data.map((er) => {
                setErro(`${er.campo}: ${er.mensagem}`)
            })
        }
        const data = {
            corPrimaria: LayoutAtual.corPrimaria,
            corSecundaria: LayoutAtual.corSecundaria,
            corTerciaria: LayoutAtual.corTerciaria,
            logoLogin: LayoutAtual.logoLogin,
            logoNavbar: LayoutAtual.logoNavbar,
            logoMenu: LayoutAtual.logoMenu,
            logoBackground: LayoutAtual.logoBackground,
            url: LayoutAtual.url,
            key: LayoutAtual.keyLayout
        };
        return axios({
            url: baseurl + "ConfiguracaoCor/Alterar",
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
            data: data,
        })
            .then(() => {
                setSuccess("Layout alterado com sucesso!");
                setOpenModalEditarLayout(false);
                setTimeout(() => {
                    setSuccess("");
                }, 6000);
                setLoading(false);
                obterLayouts()
                setLoading(false)
                setLayoutAtual({})
            })
            .catch((error) => {
                setLoading(false);
                if (error.response.status === 401) {
                    setAuthAdmin(false)
                    setErro(`Sua sessão expirou, faça login novamente`)
                    setTimeout(() => {
                        setErro("");
                    }, 6000);
                    setLoading(false)
                    return
                }
                error.response.data.map((er) => {
                    setErro(`${er.campo}: ${er.mensagem}`)
                })
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return Promise.reject(error);
            });
    }

    const obterEmpresa = async (key) => {
        const token = Cookies.get("TokenAdmin");
        setLoading(true)
        return axios({
            url: baseurl + "Empresa/ObterKey?key=" + key,
            method: "GET",
            timeout: 15000,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                setLoading(false)
                setEmpresaAtual(response.data)
                setOpenModalEditar(true)
            })
            .catch((error) => {
                setLoading(false)
                if (error.response?.status === 401) {
                    setAuthAdmin(false)
                    setErro(`Sua sessão expirou, faça login novamente`)
                    setTimeout(() => {
                        setErro("");
                    }, 6000);
                    return
                }
                return Promise.reject(error);
            })
    }

    const obterLayouts = useCallback(async () => {
        let arrayLayouts = [];
        let objLayouts = {};
        const token = Cookies.get("TokenAdmin");
        setLoading(true)
        return axios({
            url: baseurl + "ConfiguracaoCor/Listar",
            method: "GET",
            timeout: 15000,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                setLoading(false)
                const layouts = eval(response.data);
                layouts.forEach((e) => {
                    objLayouts = {
                        corPrimaria: e.corPrimaria,
                        corSecundaria: e.corSecundaria,
                        corTerciaria: e.corTerciaria,
                        id: e.id,
                        keyLayout: e.key,
                        logoBackground: e.logoBackground,
                        logoLogin: e.logoLogin,
                        logoMenu: e.logoMenu,
                        logoNavbar: e.logoNavbar,
                        url: e.url
                    };
                    arrayLayouts.push(objLayouts);
                })
                setLayoutsLista(arrayLayouts)
            })
            .catch((error) => {
                setLoading(false)
                if (error.response?.status === 401) {
                    setAuthAdmin(false)
                    setErro(`Sua sessão expirou, faça login novamente`)
                    setTimeout(() => {
                        setErro("");
                    }, 6000);
                    return
                }
                return Promise.reject(error);
            })

    }, [])

    const configLayout = (props) => {
        setLayoutAtual(props)
        setOpenModalEditarLayout(true)
    }

    const selecionarEmpresa = (emp) => {
        setEmpresaAtual(emp)
        setOpenModalEditar(true)
    }

    const deletarLayout = async () => {
        setLoading(true)
        const token = Cookies.get("TokenAdmin");
        return axios({
            url: baseurl + "ConfiguracaoCor/Deletar?key=" + keyLayout,
            method: "DELETE",
            timeout: 15000,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(() => {
                setOpenModalExcluirLayout(false)
                setLoading(false)
                setSuccess("Layout deletado com sucesso!")
                obterLayouts()
            })
            .catch((error) => {
                setLoading(false)
                error.response.data.map((er) => {
                    setErro(`${er.campo}: ${er.mensagem}`)
                })
                if (error.response?.status === 401) {
                    setAuthAdmin(false)
                    setErro(`Sua sessão expirou, faça login novamente`)
                    setTimeout(() => {
                        setErro("");
                    }, 6000);
                    return
                }
                return Promise.reject(error);
            })
    }

    
  const statusEmpresa = async (key, status) => {
    setLoading(true)
    const token = Cookies.get("TokenAdmin");
    return axios({
      url: baseurl + "Empresa/Ativo?key=" + key + "&status=" + status,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        if (status) {
          setSuccess("Empresa ativada com sucesso.")
        } else {
          setSuccess("Empresa inativada com sucesso.")
        }
        setTimeout(() => {
          setSuccess("");
        }, 6000);
        obterEmpresas()
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setErro(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setErro("");
          }, 6000);
          setAuthAdmin(false)
          return
        }
        error.response.data.map((er) => {
          setErro(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setErro("");
        }, 6000);
        return Promise.reject();
      });
  }

    useLayoutEffect(() => {
        const token = Cookies.get("TokenAdmin")
        if (token) {
            setAuthAdmin(true)
        }
    }, [])

    let data = {
        AuthUserAdmin,
        email, setEmail,
        password, setPassword,
        loading, setLoading,
        Erro, setErro,
        Success, setSuccess,
        AuthAdmin, setAuthAdmin,
        obterEmpresas,
        Empresas, setEmpresas,
        openModal, setOpenModal,
        CadastrarEmpresa,
        Cadastro, setCadastro,
        obterEmpresa,
        openModalEditar, setOpenModalEditar,
        EmpresaAtual, setEmpresaAtual,
        selecionarEmpresa,
        EditarEmpresa,
        openModalLayout, setOpenModalLayout,
        configLayout,
        CadastrarLayout,
        DadosLayout, setDadosLayout,
        openModalEditarLayout, setOpenModalEditarLayout,
        obterLayouts,
        LayoutsLista, setLayoutsLista,
        LayoutAtual, setLayoutAtual,
        EditarLayout,
        deletarLayout,
        openModalExcluirLayout, setOpenModalExcluirLayout,
        keyLayout, setKeyLayout,
        statusEmpresa
    }

    return (
        <AdminContext.Provider value={data}>
            {children}
        </AdminContext.Provider>
    )
}