import '../../App.css';
import React, { useCallback, useContext, useEffect } from 'react';
import Footer from '../../components/footer/Footer'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { OverlayTrigger, Tooltip, DropdownButton, Dropdown } from 'react-bootstrap'
import { TbPencil, TbPaint, TbPlus } from "react-icons/tb";
import { FaPlus } from 'react-icons/fa'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { StyleContext } from '../../context/StyleContext';
import { AdminContext } from '../../context/AdminContext';
import HeaderAdmin from './HeaderAdmin';
import MenuLateralAdmin from './MenuLateralAdmin';
import ModalCriarEmpresa from './components/ModaCriarEmpresa';
import ModalEditarEmpresa from './components/ModalEditarEmpresa';
import ModalCriarLayout from './components/ModalCriarLayout';

function Empresas() {
    const { Empresas, Success, Erro, obterEmpresas, loading, openModal, setOpenModal, openModalEditar, setOpenModalEditar, selecionarEmpresa, openModalLayout, setOpenModalLayout, statusEmpresa } = useContext(AdminContext)
    const { h3Demanda, btnCriar, corTerciaria } = useContext(StyleContext)
    const toolbarOptions = ['Search'];

    const gridTemplate = useCallback((props) => {
        return (<tr className='tr-ciclo'>
            <td className="td-ciclo">{props.razaoSocial}</td>
            <td className="td-ciclo">{props.cnpj}</td>
            <td className="td-ciclo">{props.telefone}</td>
            <td className="td-ciclo">{props.email}</td>
            <td className="td-ciclo" >
                <div className="checkbox-wrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input type="checkbox" label=" Ativo" className="toggleBtn" defaultChecked={props.ativo} onChange={(e) => statusEmpresa(props.keyEmpresa, e.target.checked)} />
                </div>
            </td>

            <td className="td-ciclo">
                <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip id="button-tooltip">
                        Editar empresa
                    </Tooltip>}
                ><button onClick={() => selecionarEmpresa(props)} className='btn-dem' style={{ color: `${corTerciaria}` }} ><TbPencil /></button>
                </OverlayTrigger>
            </td>
        </tr >);
    }, [Empresas]);

    useEffect(() => {
        obterEmpresas("")
    }, [])

    return (
        <div>
            <HeaderAdmin pagina={'Cadastros'} subpagina={'Cadastrar empresa'}></HeaderAdmin>
            <MenuLateralAdmin></MenuLateralAdmin>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Erro &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Erro}
                </div>
            }
            <div className='container-principal'>
                <div className='demanda-header'>
                    {openModal &&
                        <ModalCriarEmpresa closeModal={setOpenModal} />
                    }
                    {openModalEditar &&
                        <ModalEditarEmpresa closeModal={setOpenModalEditar} />
                    }
                    {openModalLayout &&
                        <ModalCriarLayout closeModal={setOpenModalLayout} />
                    }
                    {loading &&
                        <div className="loading-screen">
                            <div className="loader-container">
                                <div className="spinner"></div>
                            </div>
                        </div>
                    }
                    <button className='btn-dash btn-criar' onClick={() => setOpenModal(true)} style={btnCriar} ><FaPlus style={{ marginRight: "10px" }} />Cadastrar empresa</button>
                </div>
                <div className='card card-table'>
                    <h3 className='h3-demanda' style={h3Demanda}>Empresas cadastradas</h3>
                    <GridComponent id="grid" dataSource={Empresas} toolbar={toolbarOptions} rowTemplate={gridTemplate} allowSorting={true} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective field='label' headerText='Razão Social' />
                            <ColumnDirective field='cnpj' headerText='CNPJ' />
                            <ColumnDirective field='telefone' headerText='Telefone' />
                            <ColumnDirective field='email' headerText='E-mail' />
                            <ColumnDirective field='ativo' headerText='Status' />
                            <ColumnDirective headerText='' />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Toolbar]} />
                    </GridComponent>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Empresas;