import '../../App.css';
import React, { useContext } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';

function ModalAdicionarDemandaCiclo({ DemandasCiclo }) {

    const { CadastroCiclo, setCadastroCiclo, setAddDemandaCiclo, Ciclos, demandaCicloAtual, CadastrarCiclo } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setCadastroCiclo({ ...CadastroCiclo, [event.target.name]: event.target.value })
    }

    const salvarCiclo = () => {
        CadastrarCiclo()
            .then(() => {
                DemandasCiclo.length = 0;
                CadastroCiclo.cicloDemandaKey = '';
                CadastroCiclo.cicloOrdem = 0;
            })
    }

    const fecharModal = () => {
        setAddDemandaCiclo(false)
    }

    const htmlString = demandaCicloAtual

    return (
        <div className="modal-criar-demanda">
            <div className="modal-criar-ciclo-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Adicionar demanda ao ciclo</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => fecharModal()}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content-criar-demanda">
                    <Row>
                        <Form.Group className="mt-3">
                            <Form.Label>Demanda:</Form.Label>
                            <Form.Label style={{ marginLeft: '8px' }}><div dangerouslySetInnerHTML={{ __html: htmlString }} /></Form.Label>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ciclo</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={handleChange} name="cicloDemandaKey">
                                <option value="0">Selecione</option>
                                {Ciclos?.map((f) => {
                                    return (
                                        <option key={f.id} value={f.chave}>{f.descricao}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ordem</Form.Label>
                            <Form.Control onChange={handleChange} type='number' name="cicloOrdem" />
                        </Form.Group>
                    </Row>
                </div>
                <div className="footer-modal-criar-demanda">
                    <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fecharModal()}>Cancelar</button>
                    <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => salvarCiclo()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalAdicionarDemandaCiclo