import React, { useContext, useEffect } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import SelectEmpresa from "../pages/SelectEmpresa/SelectEmpresa";
import Dashboard from "../pages/Dashboard/Dashboard";
import LinhaAtendimentoDemanda from "../pages/LinhaAtendimentoDemanda/LinhaAtendimentoDemanda";
import CapacidadeNegocioDemanda from "../pages/CapacidadeNegocioDemanda/CapacidadeNegocioDemanda";
import CategorizacaoEstrategicaDemanda from "../pages/CategorizacaoEstrategicaDemanda/CategorizacaoEstrategicaDemanda";
import SetorGerenciaDemanda from "../pages/SetorGerenciaDemanda/SetorGerenciaDemanda";
import TipoDemanda from "../pages/TipoDemanda/TipoDemanda";
import BSC from '../pages/BSC/BSC'
import Demanda from "../pages/Demanda/Demanda";
import CadastroEmpresa from "../pages/Empresa/CadastroEmpresa";
import CadastroUsuario from "../pages/Usuario/CadastroUsuario";
import CadastroCiclo from "../pages/Ciclo/CadastroCiclo";
import NotFound from "../pages/NotFound/NotFound";
import { ApiContext } from "../context/ApiContext";
import PriorizacaoDemandas from "../pages/PriorizacaoDemandas/PriorizacaoDemandas";
import Cookies from 'js-cookie'
import Compartilhar from "../pages/Compartilhar/Compartilhar";
import { AdminContext } from "../context/AdminContext";
import LayoutAdmin from "../pages/Admin/LayoutAdmin";

const Rotas = () => {

    const { Empresas } = useContext(ApiContext)

    const role = Cookies.get('Role')
    const key = Cookies.get('Key')

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={(Empresas?.length > 1 && (key == '' || typeof key == 'undefined')) ? <SelectEmpresa /> : <Dashboard />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/bsc" element={role == "admin" ? <BSC /> : <NotFound />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/selecionarempresa" element={<SelectEmpresa />} />
                    <Route path="/linhaatendimento" element={role == 'admin' ? <LinhaAtendimentoDemanda /> : <NotFound />} />
                    <Route path="/capacidadenegocio" element={role == 'admin' ? <CapacidadeNegocioDemanda /> : <NotFound />} />
                    <Route path="/categorizacaoestrategica" element={role == 'admin' ? <CategorizacaoEstrategicaDemanda /> : <NotFound />} />
                    <Route path="/setorgerencia" element={role == 'admin' ? <SetorGerenciaDemanda /> : <NotFound />} />
                    <Route path="/tipo" element={role == 'admin' ? <TipoDemanda /> : <NotFound />} />
                    <Route path="/demanda" element={<Demanda />} />
                    <Route path="/priorizacaodemandas" element={(role == 'admin' || role == 'supervisor') ? <PriorizacaoDemandas /> : <NotFound />} />
                    <Route path="/cadastrousuario" element={role == "admin" ? <CadastroUsuario /> : <NotFound />} />
                    <Route path="/ciclo" element={(role == 'admin' || role == 'supervisor') ? <CadastroCiclo /> : <NotFound />} />
                    <Route path="/vercompartilhada" element={<Compartilhar />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Rotas;