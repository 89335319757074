import React, { createContext, useState, useLayoutEffect, useContext } from 'react'
import axios from 'axios'
import baseurl from '../services/empresa/baseurl'
import Cookies from 'js-cookie'

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {

    const [Auth, setAuth] = useState(false)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [Erro, setErro] = useState("")
    const [Success, setSuccess] = useState("")
    const [AlterarSenhaModal, setAlterarSenhaModal] = useState(false);
    const [ResetarSenhaModal, setResetarSenhaModal] = useState(false)

    useLayoutEffect(() => {
        const token = Cookies.get("Token")
        if (token) {
            setAuth(true)
        }
    }, [])

    const AuthUser = async (event) => {
        event.preventDefault()
        let data = {
            email: email,
            senha: password,
        }
        setLoading(true)
        return axios({
            url: baseurl + 'Autenticacao/Login',
            method: 'POST',
            data: data,
        }).then((response) => {
            Cookies.set('Token', response.data.accessToken)
            Cookies.set('Username', response.data.userName)
            Cookies.set('Role', response.data.role)
            setLoading(false)
            setAuth(true)
            return Promise.resolve()
        }).catch((error) => {
            setLoading(false)
            setErro(error.response.data)
            setTimeout(() => {
                setErro("")
            }, 4000);
            return Promise.reject(error)
        })
    }

    const TrocarSenha = async (novaSenha) => {
        const token = Cookies.get("Token");
        return axios({
            url: baseurl + "Autenticacao/TrocarSenha",
            method: "PUT",
            headers: { Authorization: `Bearer ${token}` },
            data: { novaSenha: novaSenha }
        })
            .then((response) => {
                setSuccess(response.data);
                setTimeout(() => {
                    setSuccess("");
                }, 6000);
                setLoading(false)
                setAlterarSenhaModal(false)
                return Promise.resolve(response.data)
            })
            .catch((error) => {
                setErro("A senha deve ter no mínimo 8 caracteres.");
                setTimeout(() => {
                    setErro("");
                }, 6000);
                setLoading(false);
                return Promise.reject(error);
            });
    };

    const ResetarSenha = async (email) => {
        const token = Cookies.get("Token");
        setLoading(true)
        return axios({
            url: baseurl + "Autenticacao/ResetarSenha?email=" + email,
            method: "POST",
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((response) => {
                setLoading(false)
                setResetarSenhaModal(false)
                setSuccess(response.data);
                setTimeout(() => {
                    setSuccess("");
                }, 6000);
            })
            .catch((error) => {
                setLoading(false);
                setErro(error.response.data);
                setTimeout(() => {
                    setErro("");
                }, 6000);
                return Promise.reject(error);
            })
    }

    let data = {
        Auth,
        AuthUser,
        email,
        setEmail,
        password,
        setPassword,
        setAuth,
        loading,
        Erro,
        setErro,
        Success,
        setSuccess,
        TrocarSenha,
        AlterarSenhaModal,
        setAlterarSenhaModal,
        ResetarSenha,
        ResetarSenhaModal,
        setResetarSenhaModal,
    }

    return (
        <AuthContext.Provider value={data}>
            {children}
        </AuthContext.Provider>
    )
}