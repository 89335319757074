import '../../../App.css'
import React, { useContext } from "react";
import { Tab, Tabs, Form, InputGroup, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { AdminContext } from '../../../context/AdminContext';
import { StyleContext } from '../../../context/StyleContext';

function ModalCriarEmpresa({ closeModal }) {
    const { CadastrarEmpresa, Cadastro, setCadastro } = useContext(AdminContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setCadastro({ ...Cadastro, [event.target.name]: event.target.value })
    }

    return (
        <div className="modal-criar-demanda">
            <div className="modal-criar-demanda-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Cadastrar empresa</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => closeModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <form onSubmit={CadastrarEmpresa}>
                    <div className="modal-content-criar-demanda">
                        <Tabs fill>
                            <Tab eventKey="info" title={<span>Informações</span>}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Razão Social</Form.Label>
                                            <Form.Control onChange={handleChange} name="razaoSocial" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fantasia</Form.Label>
                                            <Form.Control onChange={handleChange} name="fantasia" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>CNPJ</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    as={InputMask}
                                                    mask="99.999.999/9999-99"
                                                    name="cnpj"
                                                    onChange={handleChange}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Telefone</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    as={InputMask}
                                                    mask="(99) 99999-9999"
                                                    name="telefone"
                                                    onChange={handleChange}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>E-mail da empresa</Form.Label>
                                            <Form.Control onChange={handleChange} name="email" type='email' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>E-mail de login</Form.Label>
                                            <Form.Control onChange={handleChange} name="emailLogin" type='email' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nome</Form.Label>
                                            <Form.Control onChange={handleChange} name="nome"  />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="endereco" title={<span>Endereço</span>}>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3 mt-2">
                                            <Form.Label>Endereco</Form.Label>
                                            <Form.Control onChange={handleChange} name="endereco" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Número</Form.Label>
                                            <Form.Control onChange={handleChange} name="enderecoNumero" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Bairro</Form.Label>
                                            <Form.Control onChange={handleChange} name="enderecoBairro" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Cidade</Form.Label>
                                            <Form.Control onChange={handleChange} name="enderecoCidade" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>UF</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    as={InputMask}
                                                    mask="aa"
                                                    name="enderecoUF"
                                                    onChange={handleChange}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>CEP</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    as={InputMask}
                                                    mask="99999-999"
                                                    name="enderecoCEP"
                                                    onChange={handleChange}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => closeModal(false)}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCriarEmpresa