import React, { useContext, useState } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { ThemeProvider } from 'styled-components';
import { GlobalStyled } from '../../GlobalStyled/GlobalStyled';
import { Link, useNavigate } from 'react-router-dom';
import { IoLogOutOutline } from "react-icons/io5";
import { TbReplace } from "react-icons/tb";
import { RiEdit2Line } from "react-icons/ri";
//import imglogo from '../../images/smartDemand.png';
import { ligthTheme, darkTheme } from '../../GlobalStyled/themeBlue'
import Dropdown from 'react-bootstrap/Dropdown';
import ModalAlterarSenha from '../modal/ModalAlterarSenha';
import Toggle from '../botao/BotaoTrocaTema';
import Cookies from 'js-cookie'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { BiHomeAlt } from "react-icons/bi";
import { MdOutlineChevronRight } from "react-icons/md";
import { StyleContext } from '../../context/StyleContext';

function Header({ pagina, subpagina }) {

  const empresa = process.env.REACT_APP_EMPRESA;
  const navigate = useNavigate()
  const { header, logoBranca } = useContext(StyleContext)
  const { setAuth, AlterarSenhaModal, setAlterarSenhaModal, Erro, Success } = useContext(AuthContext)

  const nome = Cookies.get("Username")
  const cargo = Cookies.get("Role")
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

  const toggleTheme = () => {
    if (theme === "light") {
      localStorage.setItem('theme', 'dark');
      setTheme('dark');
    } else {
      localStorage.setItem('theme', 'light');
      setTheme('light');
    }
  };

  const sair = () => {
    Cookies.remove("Token")
    Cookies.remove("Key")
    setAuth(false);
    navigate('/');
  }

  function obterIniciais(nome) {
    const palavras = nome.split(' ');

    let iniciais = palavras[0].charAt(0);
    if (palavras.length > 1) {
      iniciais += palavras[1].charAt(0);
    }

    return iniciais;
  }

  return (
    <>
      {Success &&
        <div className='success'>
          <IoIosCheckmarkCircle className='error-icon' />{Success}
        </div>
      }
      {Erro &&
        <div className='error'>
          <IoIosWarning className='error-icon' />{Erro}
        </div>
      }
      <ThemeProvider theme={theme === "light" ? darkTheme : ligthTheme}>
        <GlobalStyled />
        <div style={header}>
          {AlterarSenhaModal &&
            <ModalAlterarSenha />
          }
          <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '12px' }}>
            <Link to="/dashboard">
              <img className='logoMenu' src={`${logoBranca}`} alt="Logo" />
            </Link>
            <div style={{ display: 'flex' }}>
              <h1 style={{ color: '#fff', fontSize: '20px', margin: '0 10px', fontWeight: '400', display: 'flex', alignItems: 'center', marginLeft: '20px' }}>{pagina} <BiHomeAlt style={{ fontSize: '16px', marginLeft: '10px' }} /></h1>
              {subpagina &&
                <span style={{ color: '#fff' }}><MdOutlineChevronRight /> {subpagina}</span>
              }
            </div>
          </div>
          <div className='options-header'>
            <Toggle toggleTheme={toggleTheme} />
            <Dropdown>
              <Dropdown.Toggle style={{ backgroundColor: "transparent", border: "none", padding: 0 }}>
                <div className='usuario'>{obterIniciais(nome)}</div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <div className='content-dropdown'>
                    <div className='usuario'>{obterIniciais(nome)}</div>
                    <div className='options-dropdown'>
                      <span className='label-name'>{nome}</span>
                      <span className='label-charge'>{cargo}</span>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="/selecionarempresa"><button className="btn-logout"><TbReplace className='icon-logout' /> Trocar empresa</button></Link>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setAlterarSenhaModal(true)}>
                  <button className="btn-logout"><RiEdit2Line className='icon-logout' />Editar senha</button>
                </Dropdown.Item>
                <hr />
                <Dropdown.Item onClick={() => sair()}>
                  <button className="btn-logout" ><IoLogOutOutline className='icon-logout' />Sair</button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </ThemeProvider>
    </>
  )
}

export default Header