import '../../App.css';
import React, { useContext, useEffect } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa';
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import moment from 'moment';
import { useLayoutEffect } from 'react';

function ModalMostrarCiclo({ setAbreModal }) {
    const { ListaCicloDemanda, CicloDem, ExcluirDemandaCiclo, DemandaAtualKey, Success, Error, loading } = useContext(ApiContext)

    // useLayoutEffect(() => {
    //     ListaCicloDemanda()
    // }, [])

    return (
        <div className="modal-criar-demanda">
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <div className="modal-criar-ciclo-interno">
                {loading &&
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Ciclos</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setAbreModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content-criar-demanda">
                    <Row>
                        {CicloDem && CicloDem.length > 0 ? (
                            CicloDem.map((item, index) => (
                                <div key={index} style={{ borderBottom: '1px solid #ccc', padding: '10px 5px', display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: 'bold' }}>Ciclo:</Form.Label>
                                            <Form.Label style={{ marginLeft: '5px' }}>{item.cicloDescricao}</Form.Label>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: 'bold' }}>Ordem:</Form.Label>
                                            <Form.Label style={{ marginLeft: '5px' }}>{item.cicloOrdem}</Form.Label>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: 'bold' }}>Data:</Form.Label>
                                            <Form.Label style={{ marginLeft: '5px' }}>{moment(item.dataHora).format('lll')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: 'bold' }}>Usuário:</Form.Label>
                                            <Form.Label style={{ marginLeft: '5px' }}>{item.usuarioNome}</Form.Label>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: 'bold' }}>GUT:</Form.Label>
                                            <Form.Label style={{ marginLeft: '5px' }}>{item.guT_Pontuacao}</Form.Label>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label style={{ fontWeight: 'bold' }}>GUT/BSC:</Form.Label>
                                            <Form.Label style={{ marginLeft: '5px' }}>{item.guT_BSC_Pontuacao}</Form.Label>
                                        </Form.Group>
                                    </div>
                                    <button type="button" className="btn-del" onClick={() => ExcluirDemandaCiclo(DemandaAtualKey, item.cicloKey)}><BsTrash /></button>
                                </div>
                            ))
                        ) : (
                            <p style={{ textAlign: 'center', marginTop: '26px' }}>Essa demanda não está inserida em nenhum ciclo!</p>
                        )}
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ModalMostrarCiclo