import '../../App.css';
import React, { useCallback, useContext, useEffect } from 'react';
import Footer from '../../components/footer/Footer'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { TbPencil, TbTrash } from "react-icons/tb";
import { FaPlus } from 'react-icons/fa'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { StyleContext } from '../../context/StyleContext';
import { AdminContext } from '../../context/AdminContext';
import HeaderAdmin from './HeaderAdmin';
import MenuLateralAdmin from './MenuLateralAdmin';
import ModalCriarLayout from './components/ModalCriarLayout';
import ModalEditarLayout from './components/ModalEditarLayout';
import ModalExcluirLayout from './components/ModalExcluirLayout';

function Layouts() {
    const { obterLayouts, LayoutsLista, openModalLayout, setOpenModalLayout, loading, Erro, Success, configLayout, openModalEditarLayout, setOpenModalEditarLayout, setOpenModalExcluirLayout, openModalExcluirLayout, setKeyLayout } = useContext(AdminContext)
    const { h3Demanda, btnCriar, corTerciaria } = useContext(StyleContext)
    const toolbarOptions = ['Search'];

    const excLayout = (key) => {
        setOpenModalExcluirLayout(true)
        setKeyLayout(key)
    }

    const gridTemplate = useCallback((props) => {
        return (<tr className='tr-ciclo'>
            <td className="td-ciclo">{props.url}</td>
            <td className="td-ciclo"><div className="item-ativo" style={{ color: props.corPrimaria }}>⬤</div> </td>
            <td className="td-ciclo"><div className="item-ativo" style={{ color: props.corSecundaria }}>⬤</div> </td>
            <td className="td-ciclo"><div className="item-ativo" style={{ color: props.corTerciaria }}>⬤</div> </td>
            <td className="td-ciclo">
                <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip id="button-tooltip">
                        Editar layout
                    </Tooltip>}
                ><button onClick={() => configLayout(props)} className='btn-dem' style={{color: `${corTerciaria}`}} ><TbPencil /></button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip id="button-tooltip">
                        Excluir layout
                    </Tooltip>}
                ><button onClick={() => excLayout(props.keyLayout)} className='btn-dem' style={{color: `${corTerciaria}`}}  ><TbTrash /></button>
                </OverlayTrigger>
            </td>
        </tr >);
    }, [LayoutsLista]);

    useEffect(() => {
        obterLayouts("")
    }, [])

    return (
        <div>
            <HeaderAdmin pagina={'Cadastros'} subpagina={'Cadastrar empresa'}></HeaderAdmin>
            <MenuLateralAdmin></MenuLateralAdmin>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Erro &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Erro}
                </div>
            }
            <div className='container-principal'>
                <div className='demanda-header'>
                    {loading &&
                        <div className="loading-screen">
                            <div className="loader-container">
                                <div className="spinner"></div>
                            </div>
                        </div>
                    }
                    {openModalLayout &&
                        <ModalCriarLayout closeModal={setOpenModalLayout} />
                    }
                    {openModalEditarLayout &&
                        <ModalEditarLayout closeModal={setOpenModalEditarLayout} />
                    }
                    {openModalExcluirLayout &&
                        <ModalExcluirLayout closeModal={setOpenModalExcluirLayout} />
                    }
                    <button className='btn-dash btn-criar' style={btnCriar} onClick={() => setOpenModalLayout(true)}><FaPlus style={{ marginRight: "10px" }} />Cadastrar layout</button>
                </div>


                <div className='card card-table'>
                    <h3 className='h3-demanda' style={h3Demanda}>Layouts cadastrados</h3>
                    <GridComponent id="grid" dataSource={LayoutsLista} toolbar={toolbarOptions} rowTemplate={gridTemplate} allowSorting={true} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                        <ColumnsDirective>
                            <ColumnDirective field='url' headerText='Url' />
                            <ColumnDirective field='corPrimaria' headerText='Cor primária' />
                            <ColumnDirective field='corSecundaria' headerText='Cor secundária' />
                            <ColumnDirective field='corTerciaria' headerText='Cor terciária' />
                            <ColumnDirective headerText='' />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Toolbar]} />
                    </GridComponent>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Layouts;