import '../../App.css';
import React, { useContext, useEffect } from 'react';
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import ModalEditarCiclo from '../../components/modal/ModalEditarCiclo';
import ModalExcluirCiclo from '../../components/modal/ModalExcluirCiclo';
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import moment from 'moment/moment';
import { TbTrash, TbPencil } from "react-icons/tb";
import { StyleContext } from '../../context/StyleContext';

function CadastroCiclo() {
    const { Success, Error, Ciclos, ListaCiclos, modalEditarCiclo, setModalEditarCiclo, setEditarCiclo, setCicloAtualKey, loading, setModalExcluirCiclo, ExcluirCicloModal } = useContext(ApiContext)
    const { h3Demanda, corTerciaria } = useContext(StyleContext)
    const toolbarOptions = ['Search'];

    const EditCiclo = (props) => {
        setModalEditarCiclo(true)
        setCicloAtualKey(props.chave)
        setEditarCiclo(props)
    }

    const DeletarCiclo = (props) => {
        setCicloAtualKey(props.chave)
        setModalExcluirCiclo(true)
    }

    const gridTemplate = (props) => {
        return (<tr className='tr-dem'>
            <td className="td-dem">{props.descricao}</td>
            <td className="td-dem">{moment(props.dataInicial).format("DD/MM/yyyy")}</td>
            <td className="td-dem">{moment(props.dataFinal).format("DD/MM/yyyy")}</td>
            <td className="td-dem">{props.observacao ? props.observacao : '-'}</td>
            <td className="td-dem">
                <button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => EditCiclo(props)}><TbPencil /></button>
                <button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => DeletarCiclo(props)}><TbTrash /></button>
            </td>
        </tr>);
    };

    useEffect(() => {
        ListaCiclos("")
    }, [])

    return (
        <div>
            <Header pagina={'Ciclos'}></Header>
            <MenuLateral></MenuLateral>
            {modalEditarCiclo &&
                <ModalEditarCiclo />
            }
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            {ExcluirCicloModal &&
                <ModalExcluirCiclo />
            }
            <div className='container-principal'>
                <div className='demanda-header'>
                </div>
                {loading ? (
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                ) : (
                    <div className='card card-table'>
                        <h3 className='h3-demanda' style={h3Demanda}>Ciclos Cadastrados</h3>
                        <GridComponent id="ciclo" dataSource={Ciclos} toolbar={toolbarOptions} rowTemplate={gridTemplate} allowSorting={true} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                            <ColumnsDirective>
                                <ColumnDirective field='descricao' headerText='Descrição' />
                                <ColumnDirective field='dataInicial' headerText='Data Inicial' />
                                <ColumnDirective field='dataFinal' headerText='Data Final' />
                                <ColumnDirective field='observacao' headerText='Observação' />
                                <ColumnDirective headerText='Opções' />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Toolbar]} />
                        </GridComponent>
                    </div>
                )}
            </div>
            <Footer></Footer>
        </div>
    );
}

export default CadastroCiclo;