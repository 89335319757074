import '../../App.css';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { BsTrash, BsPencil } from 'react-icons/bs'
import { TbTrash, TbPencil } from "react-icons/tb";
import { FaTimes, FaPlus } from 'react-icons/fa';
import { MdOutlineCancel, MdCheckCircleOutline, MdAccessTime } from 'react-icons/md'
import { Tab, Tabs, Form, Row, Col, Card, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import ModalEditorTextoEditar from './ModalEditorTextoEditar';
import moment from 'moment';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function ModalEditarDemanda({ priorizacao }) {
    const { ListaComentario, Comentario, ListaLink, Link, Tipos, Linhas, Fases, Categorias, Capacidades, ListaTipoDemanda, ListaCategorizacao, ListaCapacidades, Setores, setGut, Gut, DiretrizEstrategica, setgutBSC, GutBSC, setlinhaAtendimento, setModalEditarDemanda, GutGravidade,
        setGutGravidade,
        GutUrgencia,
        setGutUrgencia,
        GutTendencia,
        setGutTendencia,
        BSC,
        setBSC,
        EditarDemanda,
        setAddComentario,
        setAddLink,
        AdicionarComentario,
        AdicionarLink,
        ExcluirComentario,
        ExcluirLink,
        setAddRisco,
        AdicionarRisco,
        ListaRisco,
        ExcluirRisco,
        Risco,
        comentarioRef,
        linkRef,
        descricaoRef,
        riscoJustificativaRef,
        mitigacaoJustificativaRef,
        CenarioAtual,
        ProblemaCenarioAtual,
        Objetivo,
        Resultado,
        Necessidade,
        Oportunidade,
        DataLimite,
        Esforco,
        ListaCicloDemanda,
        CicloDem,
        RTFModal,
        setRTFModal,
        Dem, setDem,
        setLoading,
        ListaSetorGerencia,
        ListaLinhaAtendimento,
        DemandaAtualKey,
        ListarCompartilhamentos,
        ListaDemCompartilhada,
        RiscoEditar, setRiscoEditar,
        EditarRisco
    } = useContext(ApiContext)

    const { btnVer, btnCriar, btnPesquisar, corTerciaria } = useContext(StyleContext)

    const [editorTexto, setEditorTexto] = useState('')
    const [valorTexto, setValorTexto] = useState('')
    const [obrigatorio, setObrigatorio] = useState(Dem.faseDemanda.descricao == 'Ideia' || Dem.faseDemanda.descricao == 'Entendimento' ? '' : '*')
    const [obrigatorioGut, setObrigatorioGut] = useState(Dem.faseDemanda.descricao == 'Ideia' || Dem.faseDemanda.descricao == 'Entendimento' || Dem.faseDemanda.descricao == 'Priorização' ? '' : '*')
    const [ValorRisco, setValorRisco] = useState()
    const [ValorRiscoJustificativa, setValorRiscoJustificativa] = useState()
    const [ValorMitigacao, setValorMitigacao] = useState()
    const [ValorMitigacaoJustificativa, setValorMitigacaoJustificativa] = useState()
    const [ValorKey, setValorKey] = useState()
    const [valorCusto, setValorCusto] = useState(Dem.valor || '')
    const [rhCusto, setrhCusto] = useState(Dem.recursosHumanosExternosCusto || '')
    const [rhHospedagem, setrhHospedagem] = useState(Dem.recursosHumanosExternosHospedagem || '')
    const [rhLocomocao, setrhLocomocao] = useState(Dem.recursosHumanosExternosLocomocao || '')
    const [rhAlimentacao, setrhAlimentacao] = useState(Dem.recursosHumanosExternosAlimentacao || '')

    const role = Cookies.get('Role')

    const handleChange = (event) => {
        setDem({ ...Dem, [event.target.name]: event.target.value })
        if (event.target.name == "guT_Gravidade") {
            setGutGravidade(event.target.value)
            setDem({ ...Dem, [event.target.name]: event.target.value })
        }
        if (event.target.name == "guT_Urgencia") {
            setGutUrgencia(event.target.value)
            setDem({ ...Dem, [event.target.name]: event.target.value })
        }
        if (event.target.name == "guT_Tendencia") {
            setGutTendencia(event.target.value)
            setDem({ ...Dem, [event.target.name]: event.target.value })
        }
        if (event.target.name == "diretrizEstrategicaId") {
            const valor = JSON.parse(event.target.value)
            setDem({ ...Dem, [event.target.name]: JSON.parse(event.target.value).id })
            setBSC(valor.peso)
        }
        if (event.target.name == "linhaAtendimento") {
            setlinhaAtendimento(event.target.value)
            setDem({ ...Dem, [event.target.name]: event.target.value })
        }
        if (event.target.name == "valor" || event.target.name == "recursosHumanosExternosCusto" || event.target.name == "recursosHumanosExternosHospedagem" || event.target.name == "recursosHumanosExternosAlimentacao" || event.target.name == "recursosHumanosExternosLocomocao") {
            const valor = event.target.value.toString()
            if (valor.length > 15) {
                switch (event.target.name) {
                    case "valor":
                        setValorCusto(valor.slice(0, 15))
                        break
                    case "recursosHumanosExternosCusto":
                        setrhCusto(valor.slice(0, 15))
                        break
                    case "recursosHumanosExternosHospedagem":
                        setrhHospedagem(valor.slice(0, 15))
                        break
                    case "recursosHumanosExternosAlimentacao":
                        setrhAlimentacao(valor.slice(0, 15))
                        break
                    case "recursosHumanosExternosLocomocao":
                        setrhLocomocao(valor.slice(0, 15))
                        break
                }

                setDem({ ...Dem, [event.target.name]: parseFloat(valor.slice(0, 15)) })
                return
            } else {
                switch (event.target.name) {
                    case "valor":
                        setValorCusto(valor)
                        break
                    case "recursosHumanosExternosCusto":
                        setrhCusto(valor)
                        break
                    case "recursosHumanosExternosHospedagem":
                        setrhHospedagem(valor)
                        break
                    case "recursosHumanosExternosAlimentacao":
                        setrhAlimentacao(valor)
                        break
                    case "recursosHumanosExternosLocomocao":
                        setrhLocomocao(valor)
                        break
                }
                setDem({ ...Dem, [event.target.name]: parseFloat(valor) })
                return
            }
        }
        if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 0) {
            setObrigatorio('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 1) {
            setObrigatorio('')
        } else if (event.target.name == 'faseDemandaId') {
            setObrigatorio('*')
        }
        if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 0) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 1) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 2) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId') {
            setObrigatorioGut('*')
        }
    }

    const handleChangeEditor = (event) => {
        if (event.target.innerHTML === "<p><br></p>") {
            setDem({ ...Dem, [event.target.id]: '' })
        }
        else {
            setDem({ ...Dem, [event.target.id]: event.target.innerHTML })
        }
    }

    const adicionarComentario = (event) => {
        const { name, value } = event.target;
        setAddComentario((prevAddComentario) => ({
            ...prevAddComentario,
            [name]: value
        }));
    }

    const adicionarLink = (event) => {
        const { name, value } = event.target;
        setAddLink((prevAddLink) => ({
            ...prevAddLink,
            [name]: value
        }));
    }

    const adicionarRisco = (event) => {
        const { name, value } = event.target;
        setAddRisco((prevAddRisco) => ({
            ...prevAddRisco,
            [name]: value
        }));
    }

    const editarRisco = (risco, riscoJustificativa, mitigacao, mitigacaoJustificativa, key) => {
        const checkboxes = document.getElementsByName("riscoAtual");
        checkboxes.forEach((checkbox) => {
            if (checkbox.value === risco) {
                checkbox.checked = true;
            }
        });

        const mitigacaoCheckboxes = document.getElementsByName("mitigacaoRisco");
        mitigacaoCheckboxes.forEach((checkbox) => {
            if (checkbox.value === mitigacao) {
                checkbox.checked = true;
            }
        });
        setRiscoEditar(true)
        setValorRisco(risco)
        setValorRiscoJustificativa(riscoJustificativa)
        setValorMitigacao(mitigacao)
        setValorMitigacaoJustificativa(mitigacaoJustificativa)
        setValorKey(key)
    }

    const cancelarRisco = () => {
        setValorRisco('')
        setValorRiscoJustificativa('')
        setValorMitigacao('')
        setValorMitigacaoJustificativa('')
        setRiscoEditar(false)

        riscoJustificativaRef.current.value = '';
        mitigacaoJustificativaRef.current.value = '';

        const checkboxes = document.getElementsByName("riscoAtual");
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });

        const mitigacaoCheckboxes = document.getElementsByName("mitigacaoRisco");
        mitigacaoCheckboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    }

    const abrirEditor = (tipo, valor) => {
        setRTFModal(true)
        setEditorTexto(tipo)
        setValorTexto(valor)
    }

    const fechar = () => {
        setDem({})
        setModalEditarDemanda(false)
    }

    function carregando() {
        return new Promise(function (resolve) {
            setLoading(true)
            setTimeout(resolve, 1)
        });
    }

    function naoCarregando() {
        return new Promise(function (resolve) {
            setLoading(resolve, false)
        });
    }

    const chamarLista = async () => {
        ListaTipoDemanda('S')
        ListaLinhaAtendimento('S')
        ListaCategorizacao('S')
        ListaCapacidades('S')
        //ListaFases()
        ListaSetorGerencia('S')
        ListaComentario()
        ListaLink()
        ListaRisco()
        ListaCicloDemanda(DemandaAtualKey)
        ListarCompartilhamentos(DemandaAtualKey)
    }

    useEffect(() => {
        chamarLista()
        setRiscoEditar(false)
    }, [])

    useEffect(() => {
        setGut(GutGravidade * GutUrgencia * GutTendencia)
        if (Gut != 0 && BSC != 0) {
            setgutBSC(Gut * BSC || 0)
            setDem({ ...Dem, ['guT_BSC_Pontuacao']: GutBSC || 0 })
        }
        else {
            setgutBSC(0)
            setDem({ ...Dem, ['guT_BSC_Pontuacao']: 0 })
        }
    }, [GutGravidade, GutUrgencia, GutTendencia, BSC, Gut])

    return (
        <Fragment>
            <div className="modal-criar-demanda">
                {RTFModal &&
                    <ModalEditorTextoEditar tipoEdicao={editorTexto} valor={valorTexto} />
                }
                <div className="modal-criar-demanda-interno">
                    <div className="header-modal-criar-demanda">
                        <div>
                            <h1 className="h1-modal">Editar demanda #{Dem.id}</h1>
                        </div>
                        <div className="btn-close-modal" onClick={() => fechar()}>
                            <FaTimes />
                        </div>
                    </div>
                    <form>
                        <div className="modal-content-criar-demanda">
                            <Tabs
                                defaultActiveKey="info"
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="info" title={<span>Informações</span>}>
                                    <Row>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>Título *</Form.Label>
                                                <Form.Control defaultValue={Dem.titulo} onChange={handleChange} name="titulo" />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Descrição *
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('descricao', Dem?.descricao)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: Dem?.descricao }}
                                                    id="descricao"
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Cenário atual *
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('cenarioAtual', CenarioAtual != '' ? CenarioAtual : Dem?.cenarioAtual)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: Dem?.cenarioAtual }}
                                                    id="cenarioAtual"
                                                />
                                            </Form.Group>
                                            <Form.Label>Fase *</Form.Label>
                                            <Form.Select aria-label="Default select example" onChange={handleChange} name="faseDemandaId">
                                                <option value="0">Selecione</option>
                                                {Fases?.map((f, index) => {
                                                    return (
                                                        <option key={index}
                                                            value={JSON.stringify({ id: f.id, descricao: f.descricao })}
                                                            selected={f.id == Dem.faseDemandaId ? true : false}
                                                        >
                                                            {f.descricao}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Problema identificado no cenário atual *
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('problemaIdentificadoCenarioAtual', ProblemaCenarioAtual !== '' ? ProblemaCenarioAtual : Dem?.problemaIdentificadoCenarioAtual)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: ProblemaCenarioAtual !== '' ? ProblemaCenarioAtual : Dem?.problemaIdentificadoCenarioAtual }}
                                                    id="problemaIdentificadoCenarioAtual"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Gerência Solicitante *</Form.Label>
                                                <Form.Select aria-label="Default select example" onChange={handleChange} name="setorGerenciaDemandaId">
                                                    <option key={0} value="0">Selecione</option>
                                                    {Setores?.map((s) => {
                                                        return (
                                                            <option key={s.descricao}
                                                                value={s.id}
                                                                selected={s.id == Dem?.setorGerenciaDemandaId ? true : false}
                                                            >
                                                                {s.descricao}</option>
                                                        )

                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Linha de atendimento *</Form.Label>
                                                <Form.Select aria-label="Default select example" defaultValue={Dem?.linhaAtendimentoId} onChange={handleChange} name="linhaAtendimentoId">
                                                    <option key={0} value="0">Selecione</option>
                                                    {
                                                        Linhas?.map((item) => {
                                                            return (
                                                                <option key={item.id} value={item.id} selected={item.id == Dem?.linhaAtendimentoId ? true : false}>{item.descricao}</option>
                                                            )
                                                        })

                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Solicitante *</Form.Label>
                                                <Form.Control value={Dem?.solicitante} onChange={handleChange} name="solicitante" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="objs" title={<span>Objetivos</span>}>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Objetivo {obrigatorio}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('objetivo', Objetivo !== '' ? Objetivo : Dem?.objetivo)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: Objetivo !== '' ? Objetivo : Dem?.objetivo }}
                                                    id="objetivo"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Necessidade de negócio {obrigatorio}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('necessidadeNegocio', Necessidade !== '' ? Necessidade : Dem?.necessidadeNegocio)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: Necessidade !== '' ? Necessidade : Dem?.necessidadeNegocio }}
                                                    id="necessidadeNegocio"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Resultado esperado {obrigatorio}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('resultadoEsperado', Resultado !== '' ? Resultado : Dem?.resultadoEsperado)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: Resultado !== '' ? Resultado : Dem?.resultadoEsperado }}
                                                    id="resultadoEsperado"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Oportunidade {obrigatorio}
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('oportunidade', Oportunidade !== '' ? Oportunidade : Dem?.oportunidade)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: Oportunidade !== '' ? Oportunidade : Dem?.oportunidade }}
                                                    id="oportunidade"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Label>Prazo para retorno esperado {obrigatorio}</Form.Label>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                value="Imediato"
                                                label="Imediato"
                                                type={type}
                                                id={`inline-${type}-1`}
                                                onChange={handleChange}
                                                name="prazoRetornoEsperado"
                                                defaultChecked={Dem?.prazoRetornoEsperado == "Imediato" ? true : false}
                                            />
                                            <Form.Check
                                                inline
                                                value="Médio"
                                                label="Médio"
                                                type={type}
                                                id={`inline-${type}-2`}
                                                onChange={handleChange}
                                                name="prazoRetornoEsperado"
                                                defaultChecked={Dem?.prazoRetornoEsperado == "Médio" ? true : false}
                                            />
                                            <Form.Check
                                                inline
                                                value="Longo"
                                                label="Longo"
                                                type={type}
                                                id={`inline-${type}-3`}
                                                onChange={handleChange}
                                                name="prazoRetornoEsperado"
                                                defaultChecked={Dem?.prazoRetornoEsperado == "Longo" ? true : false}
                                            />
                                        </div>
                                    ))}
                                </Tab>
                                <Tab eventKey="urgent" title={<span>Risco</span>}>
                                    {!RiscoEditar ?
                                        <>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Risco Atual</Form.Label>
                                                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                            {['radio'].map((type) => (
                                                                <div key={`inline-${type}`} className="mb-3">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Baixo"
                                                                        value="Baixo"
                                                                        type={type}
                                                                        id={`inline-${type}-1`}
                                                                        name="riscoAtual"
                                                                        onChange={adicionarRisco}
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Médio"
                                                                        value="Médio"
                                                                        type={type}
                                                                        id={`inline-${type}-2`}
                                                                        name="riscoAtual"
                                                                        onChange={adicionarRisco}
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Alto"
                                                                        value="Alto"
                                                                        type={type}
                                                                        id={`inline-${type}-3`}
                                                                        name="riscoAtual"
                                                                        onChange={adicionarRisco}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Mitigação de risco</Form.Label>
                                                        {['radio'].map((type) => (
                                                            <div key={`inline-${type}`} className="mb-3">
                                                                <Form.Check
                                                                    inline
                                                                    label="Não mitigável"
                                                                    value="Não mitigável"
                                                                    type={type}
                                                                    id={`inline-${type}-1`}
                                                                    name="mitigacaoRisco"
                                                                    onChange={adicionarRisco}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Parcialmente"
                                                                    value="Parcialmente"
                                                                    type={type}
                                                                    id={`inline-${type}-2`}
                                                                    name="mitigacaoRisco"
                                                                    onChange={adicionarRisco}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    label="Totalmente"
                                                                    value="Totalmente"
                                                                    type={type}
                                                                    id={`inline-${type}-3`}
                                                                    name="mitigacaoRisco"
                                                                    onChange={adicionarRisco}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Justificativa do risco</Form.Label>
                                                            <Form.Control as="textarea" rows={2} onChange={adicionarRisco} name="riscoAtualJustificativa" ref={riscoJustificativaRef} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>


                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Justificativa da mitigação de risco</Form.Label>
                                                            <Form.Control as="textarea" rows={2} onChange={adicionarRisco} name="mitigacaoRiscoJustificativa" ref={mitigacaoJustificativaRef} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarRisco()}><FaPlus style={{ marginRight: '5px' }} />Adicionar risco</button>
                                            </div>
                                        </>
                                        :
                                        <Col>
                                            <Form.Label>Risco Atual</Form.Label>
                                            <div>
                                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    {['radio'].map((type) => (
                                                        <div key={`inline-${type}`} className="mb-3">
                                                            <Form.Check
                                                                inline
                                                                label="Baixo"
                                                                value="Baixo"
                                                                type={type}
                                                                id={`inline-${type}-1`}
                                                                name="riscoAtual"
                                                                onChange={(e) => setValorRisco(e.target.value)}
                                                                defaultChecked={ValorRisco === 'Baixo' ? true : false}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Médio"
                                                                value="Médio"
                                                                type={type}
                                                                id={`inline-${type}-2`}
                                                                name="riscoAtual"
                                                                onChange={(e) => setValorRisco(e.target.value)}
                                                                defaultChecked={ValorRisco === 'Médio' ? true : false}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                label="Alto"
                                                                value="Alto"
                                                                type={type}
                                                                id={`inline-${type}-3`}
                                                                name="riscoAtual"
                                                                onChange={(e) => setValorRisco(e.target.value)}
                                                                defaultChecked={ValorRisco === 'Alto' ? true : false}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Justificativa do risco</Form.Label>
                                                    <Form.Control as="textarea" maxLength="10" rows={2} onChange={(e) => setValorRiscoJustificativa(e.target.value)} name="riscoAtualJustificativa" value={ValorRiscoJustificativa} ref={riscoJustificativaRef} />
                                                </Form.Group>
                                                <Form.Label>Mitigação de risco</Form.Label>
                                                {['radio'].map((type) => (
                                                    <div key={`inline-${type}`} className="mb-3">
                                                        <Form.Check
                                                            inline
                                                            label="Não mitigável"
                                                            value="Não mitigável"
                                                            type={type}
                                                            id={`inline-${type}-1`}
                                                            name="mitigacaoRisco"
                                                            onChange={(e) => setValorMitigacao(e.target.value)}
                                                            defaultChecked={ValorMitigacao === 'Não mitigável' ? true : false}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Parcialmente"
                                                            value="Parcialmente"
                                                            type={type}
                                                            id={`inline-${type}-2`}
                                                            name="mitigacaoRisco"
                                                            onChange={(e) => setValorMitigacao(e.target.value)}
                                                            defaultChecked={ValorMitigacao === 'Parcialmente' ? true : false}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Totalmente"
                                                            value="Totalmente"
                                                            type={type}
                                                            id={`inline-${type}-3`}
                                                            name="mitigacaoRisco"
                                                            onChange={(e) => setValorMitigacao(e.target.value)}
                                                            defaultChecked={ValorMitigacao === 'Totalmente' ? true : false}
                                                        />
                                                    </div>
                                                ))}
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Justificativa da mitigação de risco</Form.Label>
                                                    <Form.Control as="textarea" maxLength={10} rows={2} onChange={(e) => setValorMitigacaoJustificativa(e.target.value)} name="mitigacaoRiscoJustificativa" value={ValorMitigacaoJustificativa} ref={mitigacaoJustificativaRef} />
                                                </Form.Group>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <button type="button" className="btn-modal btn-comentario" onClick={() => EditarRisco(ValorRisco, ValorRiscoJustificativa, ValorMitigacao, ValorMitigacaoJustificativa, ValorKey)}><BsPencil style={{ marginRight: '5px' }} />Editar risco</button>
                                                <button type="button" className="btn-dash btn-ver" style={{ marginLeft: '10px' }} onClick={() => cancelarRisco()}>Cancelar</button>
                                            </div>
                                        </Col>
                                    }
                                    <p>Riscos Adicionados</p>
                                    <div>
                                        <div className="secao-risco">
                                            {
                                                Risco?.map((item, index) => {
                                                    return (
                                                        <div key={index} className='content-risco'>
                                                            <div>
                                                                <p>Risco Atual: <span>{item.riscoAtual}</span></p>
                                                                <p>Justificativa do risco: <span>{item.riscoAtualJustificativa}</span></p>
                                                            </div>
                                                            <div>
                                                                <p>Mitigação de risco: <span>{item.mitigacaoRisco}</span></p>
                                                                <p>Justificativa da mitigação de risco: <span>{item.mitigacaoRiscoJustificativa}</span></p>
                                                            </div>
                                                            <div>
                                                                <button type="button" className="btn-dem" style={{color: `${corTerciaria}`}} onClick={() => editarRisco(item.riscoAtual, item.riscoAtualJustificativa, item.mitigacaoRisco, item.mitigacaoRiscoJustificativa, item.chaveRisco)}><TbPencil /></button>
                                                                <button type="button" className="btn-del" onClick={() => ExcluirRisco(item.chaveRisco)}><TbTrash /></button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="params" title={<span>Parâmetros</span>}>
                                    <Row>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Form.Group className="mb-3 mt-2">
                                                <Form.Label>Expectativa de entrega</Form.Label>
                                                <DatePickerComponent format='dd/MM/yy'
                                                    value={(Dem?.expectativaEntrega === null) ? (
                                                        ""
                                                    ) : (
                                                        new Date(Dem?.expectativaEntrega)
                                                    )}
                                                    onChange={handleChange} allowEdit={false} name="expectativaEntrega" placeholder='dd/mm/aaaa' />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Data limite</Form.Label>
                                                <DatePickerComponent format='dd/MM/yy'
                                                    value={(Dem?.dataLimite === null) ? (
                                                        ""
                                                    ) : (
                                                        new Date(Dem?.dataLimite)
                                                    )}
                                                    onChange={handleChange} allowEdit={false} name="dataLimite" placeholder='dd/mm/aaaa' />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Tipo da Demanda {obrigatorio}</Form.Label>
                                                <Form.Select aria-label="Default select example" onChange={handleChange} name="tipoDemandaId">
                                                    <option key={0} value="0">Selecione</option>
                                                    {Tipos?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id} selected={item.id == Dem?.tipoDemandaId ? true : false}>
                                                                {item.descricao}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Justificativa para data limite
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('justificativaDataLimite', DataLimite !== '' ? DataLimite : Dem?.justificativaDataLimite)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: DataLimite !== '' ? DataLimite : Dem?.justificativaDataLimite }}
                                                    id="justificativaDataLimite"
                                                />
                                            </Form.Group>
                                            <Form.Label>Classificação da demanda {obrigatorio}</Form.Label>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Projeto"
                                                        value="Projeto"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        onChange={handleChange}
                                                        name="classificacaoDemanda"
                                                        defaultChecked={Dem?.classificacaoDemanda == "Projeto" ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Melhoria"
                                                        value="Melhoria"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        onChange={handleChange}
                                                        name="classificacaoDemanda"
                                                        defaultChecked={Dem?.classificacaoDemanda == "Melhoria" ? true : false}
                                                    />
                                                </div>
                                            ))}
                                            <Form.Label>Deliberação pelo comitê</Form.Label>
                                            {['radio'].map((type) => (
                                                <div key={`inline-${type}`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="Sim"
                                                        value={true}
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        onChange={handleChange}
                                                        name="deliberacaoComite"
                                                        defaultChecked={Dem?.deliberacaoComite ? true : false}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Não"
                                                        value={false}
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        onChange={handleChange}
                                                        name="deliberacaoComite"
                                                        defaultChecked={!Dem?.deliberacaoComite ? true : false}
                                                    />
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="gut/bsc" title={<span>GUT/BSC</span>}>
                                    <Row>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Esforço total (horas) {obrigatorio}</Form.Label>
                                                        <Form.Control type="number" min="0" value={Dem?.esforcoTotal} onChange={handleChange} name="esforcoTotal" />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Diretriz estratégica {obrigatorioGut}</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="diretrizEstrategicaId">
                                                            <option key={0} value="0">Selecione</option>
                                                            {DiretrizEstrategica?.map((d) => {
                                                                return (
                                                                    <option key={d.id} value={JSON.stringify({ id: d.id, peso: d.pesoBSC })} selected={d.id == Dem?.diretrizEstrategicaId ? true : false}>{d.descricao}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Justificativa do esforço
                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={<Tooltip id="button-tooltip">
                                                            Editor de texto
                                                        </Tooltip>}
                                                    ><button type='button' className='btn-dem'style={{color: `${corTerciaria}`}} onClick={() => abrirEditor('justificativaEsforcoTotal', Esforco !== '' ? Esforco : Dem?.justificativaEsforcoTotal)}><TbPencil /></button>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div
                                                    contentEditable="true"
                                                    onBlur={handleChangeEditor}
                                                    className="input-descricao"
                                                    dangerouslySetInnerHTML={{ __html: Esforco !== '' ? Esforco : Dem?.justificativaEsforcoTotal }}
                                                    id="justificativaEsforcoTotal"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Capacidade de negócio atendida {obrigatorio}</Form.Label>
                                                <Form.Select aria-label="Default select example" onChange={handleChange} name="capacidadeNegocioDemandaId">
                                                    <option key={0} value="0">Selecione</option>
                                                    {Capacidades?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id} selected={item.id == Dem?.capacidadeNegocioDemandaId ? true : false}>
                                                                {item.descricao}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Categorização estratégica {obrigatorio}</Form.Label>
                                                <Form.Select aria-label="Default select example" onChange={handleChange} name="categorizacaoEstrategicaDemandaId">
                                                    <option key={0} value="0">Selecione</option>
                                                    {Categorias?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id} selected={item.id == Dem?.categorizacaoEstrategicaDemandaId ? true : false}>
                                                                {item.descricao}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col style={{ maxWidth: '50%', boxSizing: 'border-box' }}>
                                            <Card style={{ width: '35rem' }}>
                                                <Row style={{ padding: "15px 15px" }}>
                                                    <Col style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                        <div className="container-gut">
                                                            <div>
                                                                <p className='pontuacao-gut'>Pontuação</p>
                                                                <p className='title-gut'>GUT</p>
                                                            </div>
                                                            <div>
                                                                <p className='number-gut'>{Gut.toString()}</p>
                                                            </div>
                                                        </div>
                                                        <div className="container-gut">
                                                            <div>
                                                                <p className='pontuacao-gut'>Pontuação</p>
                                                                <p className='title-gut'>GUT/BSC</p>
                                                            </div>
                                                            <div>
                                                                <p className='number-gut'>{GutBSC.toFixed(2).toString()}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Gravidade {obrigatorioGut}</Form.Label>
                                                            <Form.Select aria-label="Default select example" defaultValue={Dem?.guT_Gravidade} onChange={handleChange} name="guT_Gravidade" className="mb-3">
                                                                <option key={0} value="0">Selecione</option>
                                                                <option key={1} value="1">Sem gravidade</option>
                                                                <option key={2} value="2">Pouco grave</option>
                                                                <option key={3} value="3">Grave</option>
                                                                <option key={4} value="4">Muito grave</option>
                                                                <option key={5} value="5">Extremamente grave</option>
                                                            </Form.Select>
                                                            <Form.Label>Urgência {obrigatorioGut}</Form.Label>
                                                            <Form.Select aria-label="Default select example" defaultValue={Dem?.guT_Urgencia} onChange={handleChange} name="guT_Urgencia" className="mb-3">
                                                                <option key={0} value="0">Selecione</option>
                                                                <option key={1} value="1">Longuíssimo prazo</option>
                                                                <option key={2} value="2">Longo prazo</option>
                                                                <option key={3} value="3">Médio prazo</option>
                                                                <option key={4} value="4">Curto</option>
                                                                <option key={5} value="5">Imediatamente</option>
                                                            </Form.Select>
                                                            <Form.Label>Tendência {obrigatorioGut}</Form.Label>
                                                            <Form.Select aria-label="Default select example" defaultValue={Dem?.guT_Tendencia} onChange={handleChange} name="guT_Tendencia">
                                                                <option key={0} value="0">Selecione</option>
                                                                <option key={1} value="1">Desaparece</option>
                                                                <option key={2} value="2">Reduz-se ligeiramente</option>
                                                                <option key={3} value="3">Permanece</option>
                                                                <option key={4} value="4">Aumenta</option>
                                                                <option key={5} value="5">Piora muito</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="comentarioslinks" title={<span>Comentário/Link</span>}>
                                Adicione comentários ou links na sua demanda:
                                    <div>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Comentário</Form.Label>
                                            <Form.Control as="textarea" rows={2} onChange={adicionarComentario} name="comentario" ref={comentarioRef} />
                                        </Form.Group>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarComentario()}><FaPlus style={{ marginRight: "5px" }} />Adicionar comentário</button>
                                        </div>
                                        Comentários adicionados
                                        <div className="secao-comentario">
                                            {Comentario?.map((item) => {
                                                return (
                                                    <div key={item.chaveComentario} className="content-comentario">
                                                        <div>
                                                            {/* <p className="p-title">{item.nome}</p> */}
                                                            <p className="p-content">{moment(item.dataHora).format('L')} às {moment(item.dataHora).format('LT')}</p>
                                                        </div>
                                                        <div className="div-comentario">
                                                            <p className="p-title">Comentário:</p>
                                                            <span><p dangerouslySetInnerHTML={{ __html: item.comentario }}></p></span>
                                                        </div>
                                                        <div className="div-button">
                                                            <button type="button" className="btn-del" onClick={() => ExcluirComentario(item.chaveComentario)}><TbTrash /></button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Link</Form.Label>
                                            <Form.Control onChange={adicionarLink} name="addLink" ref={linkRef} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                        <Form.Label>Descrição</Form.Label>
                                            <Form.Control as="textarea" rows={2} onChange={adicionarLink} name="descricaoLink" ref={descricaoRef} />
                                        </Form.Group>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarLink()}><FaPlus style={{ marginRight: "5px" }} />Adicionar link</button>
                                        </div>
                                        Links adicionados
                                        <div className="secao-link">
                                            {Link?.map((item) => {
                                                return (
                                                    <div key={item.chaveLink} className="content-comentario">
                                                        <div>
                                                            {/* <p className="p-title">{item.nome}</p> */}
                                                            <p className="p-content">{moment(item.dataHora).format('L')} às {moment(item.dataHora).format('LT')}</p>
                                                        </div>
                                                        <div className="div-link">
                                                            <p className="p-title">Link: </p>
                                                            <p><a href={item.link} target="_blank">{item.link}</a></p>
                                                        </div>
                                                        <div className="div-comentario">
                                                            <p className="p-title">Comentário: </p>
                                                            <span><p >{item.descricao}</p></span>
                                                        </div>
                                                        <div className="div-button">
                                                            <button type="button" className="btn-del" onClick={() => ExcluirLink(item.chaveLink)}><BsTrash /></button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="ciclos" title={<span>Ciclos</span>}>
                                    {CicloDem && CicloDem.length > 0 ? (
                                        CicloDem.map((item, index) => (
                                            <div key={index} style={{ borderBottom: '1px solid #ccc', padding: '10px 5px' }}>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Ciclo:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.cicloDescricao}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Ordem:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.cicloOrdem}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Data:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{moment(item.dataHora).format('lll')}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Usuário:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.usuarioNome}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>GUT:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.guT_Pontuacao}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>GUT/BSC:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.guT_BSC_Pontuacao}</Form.Label>
                                                </Form.Group>
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ textAlign: 'center' }}>Essa demanda não está inserida em nenhum ciclo!</p>
                                    )}
                                </Tab>
                                <Tab eventKey="share" title={<span>Compartilhar</span>}>
                                    {ListaDemCompartilhada && ListaDemCompartilhada.length > 0 ? (
                                        ListaDemCompartilhada.map((item) => (
                                            <div key={item.id} style={{ borderBottom: '1px solid #ccc', padding: '10px 5px' }}>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Usuário:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.usuario.nome}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Data de validade:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{moment(item.dataValidade).format('lll')}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Quantidade de Leituras:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.quantidadeLeituras}</Form.Label>
                                                </Form.Group>
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ textAlign: 'center' }}>Essa demanda não foi compartilhada!</p>
                                    )}
                                </Tab>
                                {Dem.podeVerValores &&
                                    <Tab eventKey="custos" title={<span>Custos</span>}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>Valor Demanda</Form.Label>
                                                    <Form.Control type="number" onChange={handleChange} name="valor" value={valorCusto} />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>RH Interno Quantidade</Form.Label>
                                                    <Form.Control type="number" defaultValue={Dem.recursosHumanosInternosQuantidade} onChange={handleChange} name="recursosHumanosInternosQuantidade" />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>RH Interno Competência</Form.Label>
                                                    <Form.Control maxLength="100" defaultValue={Dem.recursosHumanosInternosCompetencias} onChange={handleChange} name="recursosHumanosInternosCompetencias" />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>RH Externo Custo</Form.Label>
                                                    <Form.Control type="number" defaultValue={Dem.recursosHumanosExternosCusto} onChange={handleChange} name="recursosHumanosExternosCusto" value={rhCusto} />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>RH Externo Hospedagem</Form.Label>
                                                    <Form.Control type="number" defaultValue={Dem.recursosHumanosExternosHospedagem} onChange={handleChange} name="recursosHumanosExternosHospedagem" value={rhHospedagem} />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>RH Externo Alimentação</Form.Label>
                                                    <Form.Control type="number" defaultValue={Dem.recursosHumanosExternosAlimentacao} onChange={handleChange} name="recursosHumanosExternosAlimentacao" value={rhAlimentacao} />
                                                </Form.Group>

                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>RH Externo Locomoção</Form.Label>
                                                    <Form.Control type="number" defaultValue={Dem.recursosHumanosExternosLocomocao} onChange={handleChange} name="recursosHumanosExternosLocomocao" value={rhLocomocao} />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>RH Externo Tempo</Form.Label>
                                                    <Form.Control maxLength="100" defaultValue={Dem.recursosHumanosExternosTempo} onChange={handleChange} name="recursosHumanosExternosTempo" />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>RH Externo Tipo Atendimento</Form.Label>
                                                    <Form.Control maxLength="100" defaultValue={Dem.recursosHumanosExternosTipoAtendimento} onChange={handleChange} name="recursosHumanosExternosTipoAtendimento" />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>Infraestrutura</Form.Label>
                                                    <Form.Control maxLength="100" defaultValue={Dem.infraestruturaNecessariaExecucao} onChange={handleChange} name="infraestruturaNecessariaExecucao" />
                                                </Form.Group>
                                                <Form.Group className="mb-3 mt-2">
                                                    <Form.Label>Ano Base</Form.Label>
                                                    <Form.Control type="number" defaultValue={Dem.anoBaseOrcamento} onChange={handleChange} name="anoBaseOrcamento" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Tab>
                                }
                            </Tabs>
                        </div>
                        <div className="footer-modal-editar-demanda">
                            <div>
                                <b>{Dem.cancelamentoDataHora && 'Cancelada em: ' + moment(Dem.cancelamentoDataHora).format('L') + ' às ' + moment(Dem.cancelamentoDataHora).format('LT')} {Dem.conclusaoDataHora && 'Concluída em: ' + moment(Dem.conclusaoDataHora).format('L') + ' às ' + moment(Dem.conclusaoDataHora).format('LT')}</b>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fechar()}>Cancelar</button>
                                <button type="button" className="btn-dash btn-criar" style={btnCriar} onClick={() => EditarDemanda(priorizacao)}>Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )

}


export default ModalEditarDemanda