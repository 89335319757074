import '../../App.css';
import React, { useContext, useEffect } from "react";
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import BotaoNovoCadastro from '../../components/botao/BotaoNovoCadastro'
import Footer from '../../components/footer/Footer'
import ModalEditarCadastro from '../../components/modal/ModalEditarCadastro';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { TbPencil } from "react-icons/tb";
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { StyleContext } from '../../context/StyleContext';

function TipoDemanda() {
    const { Tipos, modalEditarOpen, loading, ListaTipoDemanda, setModalEditar, setDemandaAtual, Success, Error } = useContext(ApiContext)
    const toolbarOptions = ['Search'];
    const { h3Demanda, corTerciaria } = useContext(StyleContext)

    const EditCadastro = (props) => {
        setDemandaAtual(props)
        setModalEditar(true)
    }

    const gridTemplate = (props) => {
        return (<tr className='tr-dem'>
            <td className="td-dem">{props.descricao}</td>
            <td className="td-dem">
                {(props.ativo.toString() == 'true') ? (
                    <div className="item-ativo">⬤</div>
                ) : (
                    <div className="item-inativo">⬤</div>
                )}
            </td>
            <td className="td-dem">
                <button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => EditCadastro(props)}><TbPencil /></button>
            </td>
        </tr>);
    };

    useEffect(() => {
        ListaTipoDemanda("N")
    }, [])

    return (
        <>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <Header pagina={'Cadastros'} subpagina={'Tipo demanda'}></Header>
            <MenuLateral></MenuLateral>
            <div className="container-principal">
                <div className="demanda-header">
                    <BotaoNovoCadastro end="TipoDemanda" />
                    {modalEditarOpen &&
                        <ModalEditarCadastro end="TipoDemanda" />
                    }
                </div>
                <div className="card card-table">
                    <h3 className='h3-demanda' style={h3Demanda}>Tipo da Demanda</h3>
                    <div className="table-responsive text-nowrap">
                        {loading ? (
                            <div className="loading-screen">
                                <div className="loader-container">
                                    <div className="spinner"></div>
                                </div>
                            </div>
                        ) : (
                            <div className='tabela-geral'>
                                <GridComponent dataSource={Tipos} toolbar={toolbarOptions} rowTemplate={gridTemplate} allowPaging={true} allowSorting={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                                    <ColumnsDirective >
                                        <ColumnDirective field='descricao' headerText='Descrição' width='400' textAlign='Left'></ColumnDirective>
                                        <ColumnDirective field='ativo' headerText='Ativo' width='130'></ColumnDirective>
                                        <ColumnDirective headerText='Opções' width='160'></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Page, Sort, Toolbar]} />
                                </GridComponent>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default TipoDemanda;